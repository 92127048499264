import React from "react";
import { Offcanvas, Ripple, Dropdown, initTE } from "tw-elements";
import { IMAGES } from "../stores";
function PopularSearch() {
  initTE({ Offcanvas, Ripple, Dropdown });

  return (
    <>
      <div className="font-Helvetica ">
        <div className="font-Helvetica   top-5 lg:mx-auto md:container  md:mx-auto px-10 lg:px-40 md:px-10 px-5 py-10">
          <div className="font-Helvetica  pb-10">
            <p className="font-Helvetica  text-3xl font-semibold text-center text-legavacBlue">
              About LegaVac
            </p>
          </div>

          <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 place-items-center">
            <div>
              <img src={IMAGES.FIR_REGISTRATION} />{" "}
            </div>
            <div>
              <p className="p-2 text-lg">
                Welcome to LegaVac Services Private Limited, your trusted
                partner in legal solutions. As a leading provider of
                comprehensive legal services in India, we strive to empower
                individuals and businesses through our specialized offerings:
                FIR Registration, Litigation Management, and Legal Detrum - the
                world's largest Lawyers Network.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PopularSearchBlog({ list }) {
  return (
    <>
      <div>
        <p className="font-Helvetica  text-center text-legavacBlue">{list}</p>
      </div>
    </>
  );
}

export default PopularSearch;
