import React from "react";
import Testimonials from "../../components/Testimonials";
import JobListing from "../../components/JobListing";
import AdvSearchSection from "../../components/AdvSearchSection";
import PopularSearch from "../../components/PopularSearch";
import Count from "../../components/Count";
import Products from "../../components/Products";
// import{Products} from "./../sub/services/Products"

function Home() {
  return (
    <>
      <AdvSearchSection />

      {/* <JobListing /> */}
      <PopularSearch />
      <Products />
      {/* <Testimonials /> */}
      <Count />
    </>
  );
}

export default Home;
