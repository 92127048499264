const IMAGES = {
  Privacy: require("../assets/images/privacy-policy.jpg"),
  FooterMan: require("../assets/images/footer-man.png"),
  WhiteLogo: require("../assets/images/white-logo.png"),
  Login: require("../assets/images/lady.jpg"),
  MainLogo: require("../assets/images/logo.png"),
  Profile: require("../assets/images/profile.jpg"),
  Profile2: require("../assets/images/Profile2.jpg"),
  SurabhaSir: require("../assets/images/Adv-Saurabh-dhage.jpg"),
  SunilSir: require("../assets/images/sunilsir.jpg"),
  contactUsBg: require("../assets/images/contact-us.jpg"),
  CompanyLogo: require("../assets/images/company-logo.png"),
  TopSection: require("../assets/images/LegaVac-Slider.jpg"),
  PopularSearchBg: require("../assets/images/popular-search-bg.jpg"),
  jobBg: require("../assets/images/job-bg.jpg"),
  jobLoc: require("../assets/images/job-Location.jpg"),
  HomeImg: require("../assets/images/Bg-Img.jpg"),
  AboutUs1: require("../assets/images/About-Our-Group.png"),
  AboutUs2: require("../assets/images/Who-We-Are.png"),
  accuracy: require("../assets/images/accuracy.png"),

  logistics: require("../assets/images/logistics.png"),
  analysis: require("../assets/images/analysis.png"),
  dashboard: require("../assets/images/dashboard.png"),
  development: require("../assets/images/development.png"),
  humanresources: require("../assets/images/human-resources.png"),
  job: require("../assets/images/job.png"),
  management: require("../assets/images/management.png"),
  teamwork: require("../assets/images/teamwork.png"),
  vacancy: require("../assets/images/vacancy.png"),
  workflow: require("../assets/images/workflow.png"),

  marketResearch: require("../assets/images/market-research.png"),
  networking: require("../assets/images/networking.png"),
  cv: require("../assets/images/cv.png"),
  presentations: require("../assets/images/presentations.png"),
  socialadreach: require("../assets/images/social-ad-reach.png"),
  socialmedia: require("../assets/images/social-media.png"),

  Advertisement: require("../assets/images/advertisement.jpeg"),
  Gooogle: require("../assets/images/google-icon.png"),
  Linkedin: require("../assets/images/linkedin-icon.png"),
  Facebook: require("../assets/images/facebook-icon.png"),

  AI: require("../assets/images/artificial-intelligence.png"),
  database: require("../assets/images/database.png"),
  link: require("../assets/images/link.png"),
  monitor: require("../assets/images/monitor.png"),
  notification: require("../assets/images/notification.png"),
  process: require("../assets/images/process.png"),
  webDesign: require("../assets/images/web-design.png"),

  dataSource: require("../assets/images/data-source.png"),
  improve: require("../assets/images/improve.png"),
  invoice: require("../assets/images/invoice.png"),
  managementVendor: require("../assets/images/management_vendor.png"),
  request: require("../assets/images/request.png"),
  reviews: require("../assets/images/reviews.png"),
  takecare: require("../assets/images/take-care.png"),
  user: require("../assets/images/user.png"),

  checkList: require("../assets/images/check-list.png"),
  legaldocument: require("../assets/images/legal-document.png"),
  managementRecruit: require("../assets/images/managementRecruit.png"),
  planning: require("../assets/images/planning.png"),
  qualityControl: require("../assets/images/quality-control.png"),

  contract: require("../assets/images/contract.png"),
  headhunting: require("../assets/images/headhunting.png"),
  managementWorkFlow: require("../assets/images/managementWorkFlow.png"),
  recruitment: require("../assets/images/recruitment.png"),

  article: require("../assets/images/article.png"),
  article2: require("../assets/images/article2.png"),
  article3: require("../assets/images/article3.jpg"),
  article4: require("../assets/images/article4.jpg"),
  article5: require("../assets/images/article5.png"),

  lawyer2: require("../assets/images/lawyer-2.jpg"),

  profilePhoto: require("../assets/images/profilePic.png"),

  // legal detrum
  LD: require("../assets/images/product/LD.png"),
  WelcomeBack: require("../assets/images/product/Welcomeback.png"),
  CompanyAddr: require("../assets/images/product/Companyaddress.png"),
  LoyalDb: require("../assets/images/product/Advocate.png"),
  Filter: require("../assets/images/product/FindAdvocate.png"),

  // legal detrum mobile mockups
  LD_slider: require("../assets/images/LD.png"),

  FIR_REGISTRATION: require("../assets/images/FIR-Filing.jpg"),
  Litigation: require("../assets/images/legavac1.png"),
  missionVision: require("../assets/images/mission-vision.jpg"),
  FIRIMG: require("../assets/images/FIRIMG.png"),
  legalRecruitment: require("../assets/images/legalRecruitment.png"),
  AboutUs: require("../assets/images/AboutUs.jpg"),
};

export { IMAGES };
