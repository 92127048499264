import React from "react";
import { IMAGES } from "../stores/index";

function Products() {
  return (
    <>
      <div className="">
        <div className="lg:container  lg:mx-auto md:container  md:mx-auto  lg:px-10 md:px-10 px-5 py-10  shadow-inner shadow-3xl border border-gray-100 bg-[#e5e8f5]">
          <div className="pb-5">
            <p className="font-Helvetica  lg:text-3xl md:text-3xl text-[23px] font-medium mb-2 text-legavacYellow">
              {" "}
              We would like to Introduce{" "}
              <span className="font-Helvetica  text-4xl font-semibold text-legavacBlue">
                Legal Detrum
              </span>{" "}
              -
            </p>
            <p className="font-Helvetica  text-3xl font-semibold pb-5">
              Largest database of experienced Lawyers.{" "}
            </p>
            <p className="font-Helvetica ">
              You know, finding a good lawyer at a crucial time is very hard and
              particularly in remote places, so <strong>Legal Detrum</strong>{" "}
              makes it easier for you.
            </p>
            <p className="font-Helvetica ">
              <strong>Legal Detrum</strong> has a huge database of experienced
              lawyers on PAN India level. You can search by state, city, subject
              matter expertise, years of experience ratings etc. Yearly
              subscription to <strong>Legal Detrum</strong> is available as
              Individual, Firm or Company level. With a Firm or Company level
              licence, 5 people can login at a time from anywhere in the world
              through weblink or app. We are continuously increasing the
              database by adding more and more experienced lawyers time to time.
              Therefore, request you to explore and use{" "}
              <strong>Legal Detrum</strong> to find a good lawyer on the click
              of a button.
            </p>
          </div>
          <div className="grid lg:grid-cols-7 md:grid-cols-2 place-items-center grid-cols-1 py-5">
            <div></div>
            <img src={IMAGES.LD} className="h-80" />
            <img src={IMAGES.WelcomeBack} className="h-80" />
            <img src={IMAGES.CompanyAddr} className="h-80" />
            <img src={IMAGES.LoyalDb} className="h-80" />
            <img src={IMAGES.Filter} className="h-80" />
            <div></div>
          </div>

          <div className="font-Helvetica  pt-10 flex justify-center">
            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              className="font-Helvetica  bg-legavacBlue text-white font-semibold tracking-wide px-5 py-2 rounded transition duration-150 ease-in-out"
            >
              <a href="https://wa.link/c8eumi" target="_blank">
                Purchase Now
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
