import React, { useCallback, useEffect } from "react";
import { MdCheck, MdOutlineErrorOutline } from "react-icons/md";
import "../App.css";

function Toster(props) {
  console.log("toastList toaster.js", props);
  const { toastList, setList } = props;
  const closeToast = useCallback(
    (id) => {
      console.log("closeToast");
      const toastListItem = toastList.filter((e) => e.id !== id);
      setList(toastListItem);
    },
    [toastList, setList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length) {
        closeToast(toastList[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, closeToast]);

  return (
    <>
      <div className="notification-container w-[400px] h-10 top-right">
        {toastList.map((toast, index) => (
          <div
            key={index}
            className="notification toast rounded border border-white"
            style={{
              backgroundColor: toast.backgroundColor,
              borderColor: toast.borderColor,
            }}
          >
            <div className="flex justify-end pr-5 pt-1">
              <button onClick={() => closeToast(toast.id)} className=" text-lg">
                X
              </button>
            </div>
            <div className="flex space-x-5">
              <div className="pl-5">
                {toast.icon === "MdOutlineErrorOutline" ? (
                  <MdOutlineErrorOutline
                    size={30}
                    style={{ borderColor: toast.borderColor,color: toast.color }}
                    className=" rounded-full"
                  />
                ) : (
                  <MdCheck
                    size={30}
                    style={{ borderColor: toast.borderColor,color: toast.color }}
                    className=" border-2 rounded-full"
                  />
                )}
              </div>
              <div>
                <p
                  className="font-semibold text-3xl"
                  style={{ color: toast.color }}
                >
                  {toast.title}
                </p>

                <div className="pb-5">
                  <p className="text-lg" style={{ color: toast.color }}>
                    {toast.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Toster;
