import React from "react";
import { BsBookmark } from "react-icons/bs";
import { IoFlagOutline } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";

function RecruiterInfo() {
  return (
    <>
      <div className="font-Helvetica  space-y-3">
        <div className="font-Helvetica  divide-y space-y-5">
          <div className="font-Helvetica  lg:flex lg:justify-between lg:space-y-0 md:space-y-3 space-y-3 items-center">
            <div>
              <p className="font-Helvetica  text-black font-semibold">
                About{" "}
                <span className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                  Company Name
                </span>
              </p>
              <p className="font-Helvetica  text-slate-500 text-sm">Job ID: 872368 </p>
            </div>
            <div>
              <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="font-Helvetica  text-xs flex border border-legavacBlue rounded text-legavacBlue hover:text-white hover:bg-legavacBlue uppercase px-5 py-2 transition duration-150 ease-in-out"
              >
                Follow
              </button>
            </div>
          </div>
          <div className="font-Helvetica  lg:flex lg:justify-between lg:space-y-0 md:space-y-3 space-y-3 items-center pt-5">
            <div className="font-Helvetica  flex space-x-3 items-center">
              <div className="font-Helvetica  rounded-full border border-slate-400">
                <MdAccountCircle size={44} className="font-Helvetica  text-slate-400" />
              </div>
              <div>
                <p className="font-Helvetica  text-black text-sm">About Recruiter</p>
                <p className="font-Helvetica  text-legavacBlue">Recruiter Name</p>
              </div>
            </div>
            <div className="font-Helvetica  lg:flex lg:space-x-2 md:space-x-2 lg:space-y-0 space-y-2 items-center">
              <div className="font-Helvetica  flex space-x-2">
              <div className="font-Helvetica  flex space-x-1 items-center rounded text-slate-600 bg-slate-200 px-2 py-2">
                <p className="font-Helvetica  text-sm text-700 font-semibold">45</p>
                <p className="font-Helvetica  text-xs">Active Jobs</p>
              </div>
              <div className="font-Helvetica  flex space-x-1 items-center rounded text-slate-600 bg-slate-200 px-2 py-2">
                <p className="font-Helvetica  text-sm text-700 font-semibold">639</p>
                <p className="font-Helvetica  text-xs">Followers</p>
              </div>
              </div>
              <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="font-Helvetica  text-xs flex border border-legavacBlue rounded text-legavacBlue hover:text-white hover:bg-legavacBlue uppercase px-5 py-2 transition duration-150 ease-in-out"
              >
                Follow
              </button>
            </div>
          </div>
        </div>
        <div>
          <p className="font-Helvetica  text-sm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
        <div className="font-Helvetica  flex space-x-2">
          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="font-Helvetica  text-xs flex space-x-1 items-center border border-legavacBlue rounded text-legavacBlue hover:text-white hover:bg-legavacBlue uppercase px-5 py-2 transition duration-150 ease-in-out"
          >
            <BsBookmark />
            <p>Save</p>
          </button>
          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="font-Helvetica  text-xs flex space-x-1 items-center border border-legavacBlue rounded text-legavacBlue hover:text-white hover:bg-legavacBlue uppercase px-5 py-2 transition duration-150 ease-in-out"
          >
            <IoFlagOutline />
            <p>Report</p>
          </button>
        </div>
      </div>
    </>
  );
}

export default RecruiterInfo;
