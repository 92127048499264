// import React from 'react'

// const ServiceContent = () => {
//     return (
//         <>
//             <div>
//                 <h4>Our Services</h4>
//                 <p>We provide a wide range of services to help you achieve your goals. Whether it’s planning and organizing an event, providing</p>
//             </div>
//             <div>
//                 <div>
//                     <div>
//                         <h2>FIR Registration</h2>
//                         <h5>Welcome to Legavac Services Private Limited, Your Premier Partner in FIR registering Services</h5>
//                         <p>Our Legal Detrum team is committed to simplifying and expediting the process of initiating legal actions by providing comprehensive support in preparing and filing FIRs. Whether you are seeking assistance, our experienced professionals are here to guide you through the necessary steps.</p>
//                     </div>
//                     <div>
//                         <h4>Key features of our FIR filing assistance services include:</h4>
//                         <div>
//                             <div>
//                                 <h5>Expert Guidance</h5>
//                                 <p>Out Knowledgeable team will provid  step-by-step guidance to ensure accurate  and complete  FIR  documentation.</p>
//                             </div>
//                             <div>
//                                 <h5>FIR Document Preparation</h5>
//                                 <p>We assist in preparing all required documents, helping you compile the necessary infromation for a thorough FIR and helps till file the FIR in concern police station</p>
//                             </div>
//                             <div>
//                                 <h5>Liaison with Authorities</h5>
//                                 <p>Our professionals can liaise with law enforcement agencies on your behalf, facilitating effective communucation throughout the process</p>
//                             </div>
//                             <div>
//                                 <h5>Register FIR</h5>
//                                 <p>We assist  to lodge the FIR in concern police station with jurisdiction and other  legal points which help to the company</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div>
//                         {/* <h5>imaage</h5> */}
//                         <h4>How Our Litigation Management Works</h4>
//                     </div>
//                     <div>
//                         <h2>Litigation Management </h2>
//                         <p>
//                             Welcome to Legavac Services Private Limited, Your Premier Partner in Litigation Management Services.
//                             Navigating the complexities of legal disputes requires a strategic and comprehensive approach. We offer Litigation Management Services tailored to meet the unique needs of the businesses. From case assessment to resolution, our expert team is dedicated to ensuring your litigation processes are efficient, cost-effective, and successful.
//                             <stong>Your Trusted Partner in Warrant Alert Services</stong>
//                             We understand the critical importance of staying informed about legal matters that may impact your business. Our Warrant Alert Service is designed to provide you with real-time, comprehensive alerts related to warrants and legal actions that could affect your operations.

//                         </p>
//                     </div>
//                 </div>
//                 <div>
//                     <div>
//                         <h2>Legal Recruitment </h2>
//                         <p>Welcome to LegaVac Services Private Limited, Your Trusted Partner in Legal and Company Secretarial Recruitment</p>
//                         <p>We specialize in connecting top legal talent with leading organizations. Our comprehensive legal recruitment services cater to a diverse range of legal roles, ensuring that your organization is equipped with the right legal professionals. Whether you are seeking experienced attorneys, legal counsel, or paralegals, we have the expertise to source candidates who align with your unique requirements.
//                             Our Company Secretarial Recruitment services focus on identifying and placing highly qualified professionals who play a crucial role in corporate governance. We understand the importance of this function and are committed to finding candidates who excel in company secretarial roles.
//                         </p>

//                         <strong>Partner with Legavac for all your legal and company secretarial recruitment needs. Contact us today to explore how we can elevate your team with top-tier professionals</strong>
//                     </div>
//                     <div>
//                         <h6>Why Choose Legavac Resources Pvt Ltd for Legal and CS Recruitment</h6>
//                         <div>
//                             <div>
//                                 <h5>Expertise</h5>
//                                 <p>Our dedicated team possesses in-depth knowledge  of legal and company secretarial recruitment.</p>
//                             </div>
//                             <div>
//                                 <h5>Tailored Solutions</h5>
//                                 <p>We understand that each organization has unique needs Our approach is customized to match your specific requirements.</p>
//                             </div>
//                             <div>
//                                 <h5>Extensive Network</h5>
//                                 <p>with an extensive network of legal and company secretarial professionals,we can quickly identify the right talent for youe team.</p>
//                             </div>
//                             <div>
//                                 <h5>commitment to Excellence</h5>
//                                 <p>are committed to delivering excellence is  recruitment services,ensuring your organization thrives with thr best legal and  company sercretarial talent.</p>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//                 <div>
//                     <h2>Our Vision</h2>
//                     <p>Protect your business brand by staying ahead of potential Legal issues and Compliance with our Services today to receive timely alerts and gain the insights you need to safeguard your operations. Your peace of mind is our priority.</p>
//                 </div>

//             </div>

//         </>
//     )
// }

// export default ServiceContent

import React from "react";
import { IMAGES } from "../stores";

const ServiceContent = () => {
  return (
    <div className="font-Helvetica  lg:mx-auto md:container  md:mx-auto   relative lg:pt-28 pt-20">
      <div className="bg-slate-50 p-5 mb-4">
        <p className="font-Helvetica  text-3xl text-center tracking-wide  text-legavacBlue font-bold pb-2">
          Our Services{" "}
        </p>
        <div className="flex justify-center items-center w-full">
          <p className="font-Helvetica text-xl text-center  text-gray-400 w-[700px]">
            Safeguard Your Brand with our Expert Legal Services, Tailored for
            Compliance and Litigation Success at LegaVac.
          </p>
        </div>
      </div>
      <div className="flex justify-center grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 px-10">
        <div className="flex items-center">
          <div className="lg:p-5 ">
            <h2 className="text-3xl font-Helvetica font-bold text-legavacYellow mb-4">
              FIR Registration
            </h2>
            <h5 className="font-bold">
              Welcome to Legavac Services Private Limited, Your Premier Partner
              in FIR registering Services
            </h5>
            <p className=" font-Helvetica text-black">
              Our Legal Detrum team is committed to simplifying and expediting
              the process of initiating legal actions by providing comprehensive
              support in preparing and filing FIRs. Whether you are seeking
              assistance, our experienced professionals are here to guide you
              through the necessary steps.
            </p>
          </div>
        </div>
        <div className="lg:p-5">
          <h3 className="lg:p-5 md:pt-5 pt-5 text-xl font-Helvetica font-semibold text-legavacBlue">
            Key features of our FIR filing assistance services include:
          </h3>
          <img src={IMAGES.FIRIMG} className="lg:mt-[-85px] w-full" />
        </div>
      </div>

      <div className="bg-slate-50">
        <div className="lg:p-5 p-10">
          <h4 className="text-3xl font-bold  text-legavacBlue">
            How Our Litigation Management Works
          </h4>
          <div className="flex justify-center grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 ">
            <div className="litigationImg flex justify-center">
              <img src={IMAGES.Litigation} className="w-full" />
            </div>

            <div className=" flex items-center">
              <div className="py-10 px-5">
                <h2 className="text-3xl font-Helvetica font-bold text-legavacYellow mb-4">
                  Litigation Management{" "}
                </h2>
                <p className="mb-4">
                  Welcome to Legavac Services Private Limited, Your Premier
                  Partner in Litigation Management Services. Navigating the
                  complexities of legal disputes requires a strategic and
                  comprehensive approach. We offer Litigation Management
                  Services tailored to meet the unique needs of businesses. From
                  case assessment to resolution, our expert team is dedicated to
                  ensuring your litigation processes are efficient,
                  cost-effective, and successful.
                </p>
                <p>
                  <strong>
                    Your Trusted Partner in Warrant Alert Services.
                  </strong>
                  We understand the critical importance of staying informed
                  about legal matters that may impact your business. Our Warrant
                  Alert Service is designed to provide you with real-time,
                  comprehensive alerts related to warrants and legal actions
                  that could affect your operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-5">
        <div className="flex justify-center grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 px-10">
          <div className="flex items-center">
            <div className="pt-10 px-5">
              <h2 className="text-3xl font-Helvetica font-bold text-legavacYellow mb-4">
                Legal Recruitment{" "}
              </h2>
              <p className="mb-4 ">
                Welcome to LegaVac Services Private Limited, Your Trusted
                Partner in Legal and Company Secretarial Recruitment
              </p>
              <p className="mb-2">
                We specialize in connecting top legal talent with leading
                organizations. Our comprehensive legal recruitment services
                cater to a diverse range of legal roles, ensuring that your
                organization is equipped with the right legal professionals.
                Whether you are seeking experienced attorneys, legal counsel, or
                paralegals, we have the expertise to source candidates who align
                with your unique requirements. Our Company Secretarial
                Recruitment services focus on identifying and placing highly
                qualified professionals who play a crucial role in corporate
                governance. We understand the importance of this function and
                are committed to finding candidates who excel in company
                secretarial roles.
              </p>
              <p>
                Partner with Legavac for all your legal and company secretarial
                recruitment needs. Contact us today to explore how we can
                elevate your team with top-tier professionals
              </p>
            </div>
          </div>
          <div className="lg:p-5">
            <h3 className="lg:p-5 md:pt-5 pt-5 text-xl font-Helvetica font-semibold text-legavacBlue">
              Why Choose Legavac Resources Pvt Ltd for Legal and CS Recruitment
            </h3>
            <div className="litigationImg flex justify-center">
              <img
                src={IMAGES.legalRecruitment}
                className="lg:mt-[-85px] w-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-2 place-items-center px-10">
        <div className="mb-5">
          <h2 className="text-3xl font-bold mb-4 text-legavacBlue ">
            Our Mission and Vision
          </h2>
          <p>
            Our mission is to protect your brand from litigation and compliance
            risks. We believe in providing comprehensive legal solutions that
            help safeguard your business interests. Our vision is to be the
            preferred partner for businesses seeking reliable legal support in
            India.
          </p>
        </div>
        <div>
          <img src={IMAGES.missionVision} className="w-96" />{" "}
        </div>
      </div>
    </div>
  );
};

export default ServiceContent;
