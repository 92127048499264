import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";

function Skills() {
  const [addSkills, setAddSkills] = useState([]);
  const [show, setShow] = useState();
  const [hide, setHide] = useState(false);
  const [state, setState] = useState({
    skills: "",
    level: "",
    errors: [],
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.skills) {
      IsValid = false;
      errors["skills"] = "This is a required";
    } else {
      errors["skills"] = "";
    }
    if (!state.level) {
      IsValid = false;
      errors["level"] = "This is a required";
    } else {
      errors["level"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = () => {
    debugger;
    if (validate()) {
      const newData = (data) => [...data, state];
      setAddSkills(newData);
      alert("done");
      setState({
        skills: "",
        level: "",
      });
      setShow(false);
      setHide(true);
    } else {
      alert("please enter all fields");
    }
  };

  const handleEdit = () => {
    setShow(true);
    setHide(false);
  };

  const funShow = () => {
    setShow(true);
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  lg:flex lg:justify-between md:flex md:justify-between lg:space-y-0 space-y-2">
            <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
              Skills
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
              />
              <p
                className="font-Helvetica  text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Skills:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="skills"
                    id="skills"
                    onChange={handleChange}
                    value={state.skills}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
                {state.errors ? (
                  <p className="text-sm text-red-600">{state.errors.skills}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Levels:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    type="text"
                    name="level"
                    id="level"
                    onChange={handleChange}
                    value={state.level}
                    className="font-Helvetica  border border-slate-200 drop-shadow text-sm rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option selected>-- Select Level --</option>
                    <option>Beginner</option>
                    <option>Intermidiate</option>
                    <option>Advance</option>
                  </select>
                </div>
                {state.errors ? (
                  <p className="text-sm text-red-600">{state.errors.level}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="font-Helvetica  ">
          <div className="font-Helvetica  flex flex-col">
            <div className="font-Helvetica  overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="font-Helvetica  inline-block min-w-full py-2 sm:px-6 lg:px-8">
                {hide ? (
                  <div className="font-Helvetica  overflow-hidden">
                    <table className="font-Helvetica  min-w-full text-left text-sm font-light">
                      <thead className="font-Helvetica  ">
                        <tr>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Skills
                          </th>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Levels
                          </th>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {addSkills.length > 0 &&
                          addSkills?.map((s, idx) => (
                            <tr className="font-Helvetica  ">
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                                {s.skills}
                              </td>
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                                {s.level}
                              </td>
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 flex space-x-3">
                                <MdEdit
                                  className="font-Helvetica  text-green-600 cursor-pointer"
                                  size={20}
                                  onClick={handleEdit}
                                />
                                <MdDelete
                                  className="font-Helvetica  text-red-500 cursor-pointer"
                                  size={20}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
