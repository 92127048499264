import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../stores";
import {  MdOutlineArrowBackIosNew } from "react-icons/md";
import VerificationEmail from "./VerificationEmail";
import Toster from "../../stores/Toster";

function Signup() {
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  // const verifiedEmail = props.verify;
  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML =
      '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const signUpDetails = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    check: "",
    errors: [],
  };

  const [state, setState] = useState(signUpDetails);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: [e.target.value] });
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const validateCandidate = () => {
    debugger;
    alert("hii");
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const mobilenumber = /^[0-9]/;

    if (!state.firstName) {
      IsValid = false;
      errors["firstName"] = "First name is required";
    } else if (state.firstName.length < 2) {
      IsValid = false;
      errors["firstName"] = "Must be 15 characters ";
    } else if (!state.firstName.match(regex_name)) {
      IsValid = false;
      errors["firstName"] = "please enter valid name";
    } else {
      errors["firstName"] = "";
    }

    if (!state.lastName) {
      IsValid = false;
      errors["lastName"] = "Last name is required";
    } else if (state.lastName.length < 2) {
      IsValid = false;
      errors["lastName"] = "please enter valid name";
    } else {
      errors["lastName"] = "";
    }

    if (!state.mobile) {
      IsValid = false;
      errors["mobile"] = "mobile nuber is required";
    } else if (state.mobile.length < 10 || state.mobile.length > 10) {
      IsValid = false;
      errors["mobile"] = "mobile numbet must have 10 digits";
    } else if (!state.mobile.match(mobilenumber)) {
      IsValid = false;
      errors["mobile"] = "please enter valid mobile number";
    } else {
      errors["mobile"] = "";
    }

    if (!state.email) {
      IsValid = false;
      errors["email"] = "Email is required";
    } else if (!state.email.match(regex_email)) {
      IsValid = false;
      errors["email"] = "please enter valid email id";
    } else {
      errors["email"] = "";
      setVerifyEmail(true);
    }

    if (!state.password) {
      IsValid = false;
      errors["password"] = "please enter password";
    } else {
      errors["password"] = "";
    }

    if (!state.check) {
      IsValid = false;
      errors["check"] = "please agree above terms";
    } else {
      errors["check"] = "";
    }

      setState({
        ...state,
        errors: errors,
      });
      return IsValid;
  };
  const clearState = () => {
    setState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      check: "",
    });
  };
  const closedModal = (e) => {
    console.log('test' , showModal)
    setShowModal(false)
  }

  const handleCandidateSubmit = () => {
    //setLoader(true);
    console.log("handleCandidateSubmit--->", state);
    // if (validateCandidate()) {
    //   alert("done");
    //   console.log("state--------->", state);
    //   //api otp  api success api of otp (check for email is already exist or not if existe thenn error else sent otp)
    //   handleSuccess();
    //   setLoader(false);
    //   clearState();
    // } else {
    //   setLoader(false);
    //   alert("please fill all the fileds");
    //   //showToaster("danger");
    // }
    handleSuccess();
  };

  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    debugger;
    setShowModal(true);
  };

  return (
    <>
      <div className="font-Helvetica w-full grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 overflow-y-auto">
        <div className="font-Helvetica lg:col-span-3 lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:w-3/5 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60">
          <img
            src={IMAGES.Login}
            className="font-Helvetica  w-full md:min-h-screen"
          />
          <Link to="/">
            <img
              src={IMAGES.MainLogo}
              alt=""
              className="font-Helvetica  w-36 z-10 absolute top-10 left-10 shadow-md shadow-white p-3"
            />
          </Link>

          <div className="font-Helvetica  absolute z-10 lg:top-1/2 md:top-80 top-40 left-5 md:left-60 space-y-5">
            <p className="font-Helvetica  font-QuickStand text-white md:text-5xl text-3xl  font-bold text-center">
              Welcome to{" "}
              <span
                className="font-QuickStand  txt-rotate text-legavacYellow"
                data-period="2000"
                data-rotate='[ "LegaVac !", "LegaVac !"]'
              ></span>
            </p>
          </div>
        </div>

        <div className="font-Helvetica lg:w-full bg-white col-span-2 lg:grid lg:place-items-end">
          <div
            className="font-Helvetica  p-5 flex space-x-2 items-center cursor-pointer"
            data-te-offcanvas-dismiss
          >
            <div className="font-Helvetica  rounded-full border border-blue-800 p-1">
              <MdOutlineArrowBackIosNew color="blue" size="10" />
            </div>
            <div>
              <Link to="/">
                <p className="font-Helvetica  text-blue-800 text-xs">
                  Back to home
                </p>
              </Link>
            </div>
          </div>

          <div className="font-Helvetica  lg:px-10 md:px-10 px-5 lg:py-0 md:py-5 py-5">
            <div className="font-Helvetica  pb-5">
              <p className="font-Helvetica  text-3xl font-semibold text-left uppercase underline underline-offset-8 decoration-legavacYellow">
                Register
              </p>
              <div>
                <div className="font-Helvetica  space-y-3 py-3">
                  <p className="font-Helvetica  text-start text-xs">
                    It only takes a couple of minutes to get started
                  </p>

                </div>
              </div>
             
                <div>
                  <div className="font-Helvetica py-5 space-y-2">
                    <div className="font-Helvetica grid grid-cols-2 gap-2">
                      <div className="font-Helvetica  space-y-1">
                        <p className="font-Helvetica  text-slate-400 text-xs">
                          First Name
                        </p>
                        <input
                          type="text"
                          placeholder="Enter first name"
                          value={state.firstName}
                          name="firstName"
                          id="firstName"
                          onChange={handleChange}
                          className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                        />
                        {state.errors ? (
                          <p className="font-Helvetica  text-red-500 text-xs pt-1">
                            {state.errors.firstName}
                          </p>
                        ) : null}
                      </div>
                      <div className="font-Helvetica  space-y-1">
                        <p className="font-Helvetica  text-slate-400 text-xs">
                          Last Name
                        </p>
                        <input
                          type="text"
                          placeholder="Enter last name"
                          name="lastName"
                          id="lastName"
                          onChange={handleChange}
                          value={state.lastName}
                          className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                        />
                        {state.errors ? (
                          <p className="font-Helvetica  text-red-500 text-xs pt-1">
                            {state.errors.lastName}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="font-Helvetica space-y-1">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        Email ID hbdhbabhabhhab
                      </p>
                      <input
                        type="text"
                        placeholder="Enter first name"
                        value={state.firstName}
                        name="firstName"
                        id="firstName"
                        onChange={handleChange}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                      {state.errors ? (
                        <p className="font-Helvetica  text-red-500 text-xs pt-1">
                          {state.errors.firstName}
                        </p>
                      ) : null}
                    </div>
                    <div className="font-Helvetica space-y-1">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        Last Name
                      </p>
                      <input
                        type="text"
                        placeholder="Enter last name"
                        // value={personalDetails.lastName}
                        name="lastName"
                        id="lastName"
                        // onChange={onchangeBasicDetails}
                        onChange={handleChange}
                        value={state.lastName}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                      {state.errors ? (
                        <p className="font-Helvetica  text-red-500 text-xs pt-1">
                          {state.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <div className="font-Helvetica space-y-1">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        Mobile Number
                      </p>
                      <div className="font-Helvetica  flex space-x-2">
                        <select className="font-Helvetica  border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs">
                          <option>+91</option>
                          <option>+92</option>
                          <option>+93</option>
                        </select>
                        <input
                          type="number"
                          placeholder="Enter mobile number"
                          name="mobile"
                          id="mobile"
                          maxLength={10}
                          onChange={handleChange}
                          value={state.mobile}
                          className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                        />
                      </div>
                      {state.errors ? (
                        <p className="font-Helvetica  text-red-500 text-xs pt-1">
                          {state.errors.mobile}
                        </p>
                      ) : null}
                    </div>

                    <div className="font-Helvetica  flex  lg:items-center md:items-center space-x-2">
                      <div>
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name="check"
                          id="check"
                        />
                      </div>
                      {loader ? <p>Loading ..........</p> : null}
                      <p className="font-Helvetica  text-xs text-slate-400">
                        By signing up, you agree to our{" "}
                        <span className="font-Helvetica  text-legavacBlue">
                          <a href="/">Terms and conditions</a> and{" "}
                          <a href="/">Privacy Policy</a>
                        </span>
                      </p>
                    </div>
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs">
                        {state.errors.check}
                      </p>
                    ) : null}
                  </div>

                  <div className="font-Helvetica  grid place-items-center">
                    <button
                      type="submit"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                      className="font-Helvetica  bg-legavacBlue font-semibold rounded text-white py-1 px-5 uppercase cursor-pointer"
                      onClick={handleCandidateSubmit}
                      // disabled={
                      //   state.firstName &&
                      //   state.lastName &&
                      //   state.email &&
                      //   state.password &&
                      //   state.check &&
                      //   state.mobile
                      // }
                      style={{
                        cursor:
                          state.firstName &&
                          state.lastName &&
                          state.email &&
                          state.password &&
                          state.check &&
                          state.mobile
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      Register ddd
                    </button>
                  </div>
                </div>
              
              <p className="font-Helvetica  text-xs text-center pt-5 text-slate-400">
                Already have an account ?{" "}
                <Link to="/login">
                  <span
                    className="font-Helvetica  text-legavacBlue hover:underline cursor-pointer"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasExample2"
                    aria-controls="offcanvasExample2"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    Login
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Toster toastList={list} setList={setList} /> */}
      {showModal && (
        <VerificationEmail isOpen={showModal} onClose={() => closedModal()}/>
      )}
    </>
  );
}

export default Signup;

// function SuccessModal(props) {
  
// }
