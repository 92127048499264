import React, { useId, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit, MdOutlineErrorOutline } from "react-icons/md";
import Toster from "../../../../stores/Toster";

function EducationDetails() {
  const [education1, setEducation1] = useState([]);

  const qualification = [
    { degree: "Any Postgraduate" },
    { degree: "PG Not Required " },
    { degree: "MBA/PGDM" },
    { degree: "M.Tech" },
    { degree: "Medical-MS/MD" },
    { degree: "MCA" },
    { degree: "MS/M.Sc(Science)" },
    { degree: "CA" },
    { degree: "M.Com" },
    { degree: "PG Diploma" },
    { degree: "M.Pharma" },
    { degree: "M.A" },
    { degree: "Any Graduate" },
    { degree: "B.Tech/B.E." },
    { degree: "Graduation Not Required" },
    { degree: "Diploma" },
    { degree: "B.Sc" },
    { degree: "B.Com" },
    { degree: "B.B.A/ B.M.S" },
    { degree: "BCA" },
    { degree: "MBBS" },
  ];

  const institute = [
    {
      colleges: "Bhavan's College of Arts, Science and Commerce, Andheri West",
    },
    {
      colleges:
        "Bunts Sangha's S.M.Shetty College of Science, Commerce and Management Studies, Powai",
    },
    { colleges: "B. K. Birla College of Arts, Science and Commerce,Kalyan[1]" },
    { colleges: "Chetana College," },
    { colleges: "C. H. M. College, Ulhasnagar" },
    {
      colleges: "D. G. Ruparel College of Arts, Science and Commerce, Matunga",
    },
    { colleges: "Elphinstone College, Kala Ghoda, Fort" },
    { colleges: "Guru Nanak Khalsa College (King's Circle), Matunga" },
    { colleges: "H.R. College of Commerce and Economics, Churchgate" },
    { colleges: "Jai Hind College, Churchgate" },
    { colleges: "Kalasadan, Sion" },
    { colleges: "Kishinchand Chellaram College, Churchgate" },
    { colleges: "R. D. National College, Bandra West" },
    { colleges: "M. M. K. College, Bandra West" },
    { colleges: "Kirti M. Doongursee College, Dadar West" },
    { colleges: "K.J. Somaiya College of Arts & Commerce, Vidyavihar" },
    { colleges: "K.J. Somaiya College of Science & Commerce, Vidyavihar" },
    {
      colleges:
        "Malini Kishor Sanghvi College of Commerce & Economics, JVPD, Vile Parle",
    },
    { colleges: "Mithibai College, Vile Parle" },
    { colleges: "Mulund College of Commerce, Mulund West" },
    { colleges: "Hinduja College, Charni Road" },
  ];

  const [state, setState] = useState({
    qualification: "",
    institute: "",
    passingyear: "",
    percentage: "",
    errors: [],
  });

  // const [education, setEducation] = useState({});
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.qualification) {
      IsValid = false;
      errors["qualification"] = "required";
    } else {
      errors["qualification"] = "";
    }
    if (!state.institute) {
      IsValid = false;
      errors["institute"] = "required";
    } else {
      errors["institute"] = "";
    }
    if (!state.passingyear) {
      IsValid = false;
      errors["passingyear"] = "required";
    } else {
      errors["passingyear"] = "";
    }
    if (!state.percentage) {
      IsValid = false;
      errors["percentage"] = "required";
    } else {
      errors["percentage"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const [list, setList] = useState([]);
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "danger") {
      ToastProperties = {
        id: list.length + 1,
        title: "Error",
        description: "Please enter all fields",
        icon:"MdOutlineErrorOutline",
        backgroundColor: "#fef2f2",
        borderColor:"#dc2626",
        color:"#dc2626"
      };
      setList([...list, ToastProperties]);
    }
  };
  const handleSubmit = () => {
    debugger;
    if (validate()) {
      // setEducation(state);
      const newData = (data) => [...data, state];
      // let temp = [];
      // temp.push(state);
      // let combinedarray=[...education1,...temp]

      setEducation1(newData);
      // console.log("setEducation", education);
      alert("done");
      setState({
        qualification: "",
        institute: "",
        passingyear: "",
        percentage: "",
      });
      setShow(false);
      setHide(true);
    } else {
      showToaster("danger");
    }
  };

  const handleEdit = () => {
    setShow(true);
    setHide(false);
  };

  const [show, setShow] = useState();
  const [hide, setHide] = useState();
  const funShow = () => {
    setShow(true);
    setHide(false);
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
              Education Details
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
              />
              <p
                className="font-Helvetica  text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Qualification:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    name="qualification"
                    id="qualification"
                    onChange={handleChange}
                    value={state.qualification}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option selected>-- Select Qualification --</option>
                    {qualification?.map((d, idx) => (
                      <option key={idx} className="font-Helvetica  text-sm">
                        {d.degree}
                      </option>
                    ))}
                  </select>
                  {state.errors ? (
                    <p className="text-sm text-red-600">
                      {state.errors.qualification}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Institute:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    name="institute"
                    id="institute"
                    onChange={handleChange}
                    value={state.institute}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option selected>-- Select Institute --</option>
                    {institute?.map((i, idx) => (
                      <option key={idx} className="font-Helvetica  text-sm">
                        {i.colleges}
                      </option>
                    ))}
                  </select>
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.institute}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Year of Passing:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="passingyear"
                    id="passingyear"
                    onChange={handleChange}
                    value={state.passingyear}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.passingyear}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Percentage / CGPA:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="percentage"
                    id="percentage"
                    onChange={handleChange}
                    value={state.percentage}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.percentage}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <ol className="font-Helvetica  border-l-2 border-info-100">
          {education1.length > 0 &&
            education1?.map((education, idx) => (
              <li key={idx}>
                <div className="font-Helvetica  flex-start md:flex">
                  <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                    <HiOutlineAcademicCap />
                  </div>
                  <div className="font-Helvetica  ml-6 mb-5 block w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                    <div className="font-Helvetica  flex justify-between">
                      <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                        {education.qualification}
                      </p>
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          size={20}
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          onClick={handleEdit}
                        />
                        <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                    <p className="font-Helvetica  text-sm">
                      {education.institute}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500">
                      {education.passingyear}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500">
                      {education.percentage}
                    </p>
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </div>
      <Toster toastList={list} setList={setList} />
    </>
  );
}

export default EducationDetails;
