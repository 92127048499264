import React from "react";
import { IMAGES } from "../../../stores/index";

function WorkFlowSolution() {
  const worksolutions = [
    {
      work: "Contract Staffing",
      icon: IMAGES.contract,
      solution: " End-to-End solution to outsource Finance and Legal functions",
    },
    {
      work: "Perm Recruitment & RPO",
      icon: IMAGES.recruitment,
      solution:
        " Powerful talent acquisition strategies to build winning workforces that thrive in the new world of work - Mid, Senior & Executive Roles",
    },
    {
      work: "Remote Hiring/ Cloud Staffing",
      icon: IMAGES.headhunting,
      solution:
        "Complete Mapping of Finance, Secretarial and Legal specialists in India for remote/offshore deployment",
    },
    {
      work: "Manage Solutions",
      icon: IMAGES.managementWorkFlow,
      solution:
        "Complete ownership of critical business support functions to achieve process excellence and reduce operational costs",
    },
  ];
  return (
    <>
      <div>
        <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-5 relative pt-28 w-full">
          <div className="font-Helvetica  ">
            <div className="font-Helvetica  mb-10">
              <p className="font-Helvetica  text-3xl  font-QuickStand font-bold text-center pb-3">
                Our Workflow Solutions
              </p>
              <hr></hr>
            </div>
            <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-5">
              <div className="font-Helvetica  relative grid place-items-center">
                <div className="font-Helvetica  bg-[#001ba1] rounded-full p-5">
                  <img src={IMAGES.contract} className="font-Helvetica  w-20 h-20 p-1" />
                </div>

                <div className="font-Helvetica  space-y-3">
                  <div className="font-Helvetica  pt-5">
                    <p className="font-Helvetica  font-bold text-center">Contract Staffing</p>
                  </div>

                  <div className="font-Helvetica  border border-dashed border-[#eb8a00] shadow-xl h-[150px] px-10 rounded-lg py-5">
                    <p className="font-Helvetica  text-sm text-center">
                      End-to-End solution to outsource Finance and Legal
                      functions
                    </p>
                  </div>
                </div>
              </div>

              <div className="font-Helvetica  relative grid place-items-center">
                <div className="font-Helvetica  bg-[#eb8a00]  rounded-full p-5">
                  <img src={IMAGES.recruitment} className="font-Helvetica  w-20 h-20 p-1" />
                </div>

                <div className="font-Helvetica  space-y-3">
                  <div className="font-Helvetica  pt-5">
                    <p className="font-Helvetica  font-bold text-center">
                      Perm Recruitment & RPO
                    </p>
                  </div>

                  <div className="font-Helvetica  border border-dashed border-[#001ba1] shadow-xl h-[150px] px-10 rounded-lg py-5">
                    <p className="font-Helvetica  text-sm text-center">
                      Powerful talent acquisition strategies to build winning
                      workforces that thrive in the new world of work - Mid,
                      Senior & Executive Roles
                    </p>
                  </div>
                </div>
              </div>

              <div className="font-Helvetica  relative grid place-items-center">
                <div className="font-Helvetica  bg-[#001ba1] rounded-full p-5">
                  <img src={IMAGES.headhunting} className="font-Helvetica  w-20 h-20 p-1" />
                </div>

                <div className="font-Helvetica  space-y-3">
                  <div className="font-Helvetica  pt-5">
                    <p className="font-Helvetica  font-bold text-center">
                      Remote Hiring/ Cloud Staffing
                    </p>
                  </div>

                  <div className="font-Helvetica  border border-dashed border-[#eb8a00] shadow-xl h-[150px] px-10 rounded-lg py-5">
                    <p className="font-Helvetica  text-sm text-center">
                      Complete Mapping of Finance, Secretarial and Legal
                      specialists in India for remote/offshore deployment
                    </p>
                  </div>
                </div>
              </div>
              <div className="font-Helvetica  relative grid place-items-center">
                <div className="font-Helvetica  bg-[#eb8a00] rounded-full p-5">
                  <img src={IMAGES.managementWorkFlow} className="font-Helvetica  w-20 h-20 p-1" />
                </div>

                <div className="font-Helvetica  space-y-3">
                  <div className="font-Helvetica  pt-5">
                    <p className="font-Helvetica  font-bold text-center">Manage Solutions</p>
                  </div>

                  <div className="font-Helvetica  border border-dashed border-[#001ba1] shadow-xl h-[150px] px-10 rounded-lg py-5">
                    <p className="font-Helvetica  text-sm text-center">
                      Complete ownership of critical business support functions
                      to achieve process excellence and reduce operational costs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkFlowSolution;
