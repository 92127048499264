import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function MyProfile() {
  const navigate = useNavigate();

  const contactDetails = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "",
    state: "",
    district: "",
    city: "",
    pincode: "",
    address: "",
    errors: [],
  };

  const [state, setState] = useState(contactDetails);
  const [showInfo, setShowInfo] = useState(false); // information in grid
  const [showForm, setShowForm] = useState(true); // to edit info
  // const basicDetails = {
  //   firstName: "",
  //   lastName: "",
  //   dob: "",
  //   description: "",
  //   mobile: "",
  //   email: "",
  //   country: "",
  //   pincode: "",
  //   city: "",
  //   address: "",
  //   error: [],
  // };

  // const [personalDetails, setPersonalDetails] = useState(basicDetails);

  // const onchangeBasicDetails = (e) => {
  //   setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
  //   console.log("state-->", personalDetails);

  //   setPersonalDetails((prev) => {
  //     return {
  //       ...prev,
  //       [e.target.name]: e.target.value,
  //       error: { ...personalDetails.error, [e.target.name]: "" },
  //     };
  //   });
  // };

  // const saveBasicDetails = (e) => {
  //   e.preventDefault();
  //   if (validation()) {
  //     setPersonalDetails({
  //       ...personalDetails,
  //       [e.target.name]: e.target.value,
  //     });
  //     console.log("personalDetails-->", personalDetails);
  //   }
  // };

  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const mobilenumber = /^[0-9]/;

    if (!state.firstName) {
      IsValid = false;
      errors.firstName = "Required";
    } else if (state.firstName.length < 2) {
      IsValid = false;
      errors.firstName = "Must be 15 characters ";
    } else if (!state.firstName.match(regex_name)) {
      IsValid = false;
      errors.firstName = "please enter valid name";
    }

    if (!state.lastName) {
      IsValid = false;
      errors.lastName = "Required";
    } else if (state.lastName.length < 2) {
      IsValid = false;
      errors.lastName = "please enter valid name";
    }

    if (!state.dob) {
      IsValid = false;
      errors.dob = "Required";
    }

    if (!state.mobile) {
      IsValid = false;
      errors.mobile = "Required";
    } else if (state.mobile.length < 10) {
      IsValid = false;
      errors.mobile = "Must be 10 digits";
    } else if (!state.mobile.match(mobilenumber)) {
      IsValid = false;
      errors.mobile = "please enter valid mobile number";
    }

    if (!state.email) {
      IsValid = false;
      errors.email = "Required";
    } else if (!state.email.match(regex_email)) {
      IsValid = false;
      errors.email = "please enter valid email id";
    }

    if (!state.city) {
      IsValid = false;
      errors.city = "Required";
    } else if (state.city.length < 2) {
      IsValid = false;
      errors.city = "please enter proper city name";
    }

    if (!state.country) {
      IsValid = false;
      errors.country = "Required";
    } else if (state.country.length < 2) {
      IsValid = false;
      errors.city = "please enter proper country name";
    }

    if (!state.district) {
      IsValid = false;
      errors.district = "Required";
    } else if (state.district.length < 2) {
      IsValid = false;
      errors.district = "please enter proper district name";
    }
    if (!state.state) {
      IsValid = false;
      errors.state = "Required";
    } else if (state.state.length < 2) {
      IsValid = false;
      errors.state = "please enter proper state name";
    }

    if (!state.address) {
      IsValid = false;
      errors.address = "Required";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: [e.target.value] });
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const handleEdit = () => {
    setShowInfo(false);
    setShowForm(true);
  };
  const handleSubmit = () => {
    if (validate()) {
      console.log("data--------------->",state)
      setShowInfo(true);
      setShowForm(false);

      alert("done");
    } else {
      alert("please enter all fields");
    }
  };

  return (
    <>
      <div className="font-Helvetica  space-y-5">
        <div className="font-Helvetica  flex justify-between border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Basic Information
          </p>
          <div className="font-Helvetica  flex items-center">
            <div className="font-Helvetica  p-1 w-4 h-4 rotate-45 translate-x-2 bg-legavacBlue"></div>
            <button
              className="font-Helvetica  z-10 bg-legavacBlue py-1 px-2 text-white font-medium text-xs"
              onClick={() => navigate("/")}
            >
              Back
            </button>
          </div>
        </div>
        {showForm ? (
          <div>
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  First Name:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    value={state.firstName}
                    name="firstName"
                    id="firstName"
                    onChange={handleChange}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"

                    // className={
                    //   state.errors.firstName
                    //     ? "border border-red-400 bg-red-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    //     : "placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    // }
                  />
                  {state.errors.firstName ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.firstName}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Last Name:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    // value={personalDetails.lastName}
                    name="lastName"
                    id="lastName"
                    // onChange={onchangeBasicDetails}
                    onChange={handleChange}
                    value={state.lastName}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors.lastName ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.lastName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Date Of Birth:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="date"
                    placeholder="Enter date of birth"
                    name="dob"
                    id="dob"
                    onChange={handleChange}
                    value={state.dob}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors.dob ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.dob}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="font-Helvetica  grid grid-cols-1 gap-5 pt-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Description:
                </p>
                <textarea
                  rows={3}
                  name="description"
                  id="description"
                  onChange={handleChange}
                  value={state.description}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
            </div>

            <div className="font-Helvetica  ">
              <div className="font-Helvetica border-b pb-5">
                <p className="font-Helvetica text-xl font-semibold tracking-wide uppercase">
                  Address Information
                </p>
              </div>
              <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 pt-5">
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Mobile no.:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter mobile number"
                      name="mobile"
                      id="mobile"
                      onChange={handleChange}
                      value={state.mobile}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors.mobile ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.mobile}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Email Id:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter email id"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={state.email}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors.email ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Country:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter country"
                      name="country"
                      id="country"
                      onChange={handleChange}
                      value={state.country}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.country}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    State:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter state"
                      name="state"
                      id="state"
                      onChange={handleChange}
                      value={state.state}
                      className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.state}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    District:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter district"
                      name="district"
                      id="district"
                      onChange={handleChange}
                      value={state.district}
                      className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.district}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    City:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter city"
                      name="city"
                      id="city"
                      onChange={handleChange}
                      value={state.city}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.city}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Postal pin:
                    {/* <span className="font-Helvetica  text-pink-600 text-xl pt-5">*</span> */}
                  </p>
                  <input
                    type="text"
                    placeholder="Enter pin code"
                    name="pincode"
                    id="pincode"
                    onChange={handleChange}
                    value={state.pincode}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Full Address:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter your address"
                      name="address"
                      id="address"
                      onChange={handleChange}
                      value={state.address}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.address}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* edit */}
        {showInfo ? (
          <div>
            <div className="grid grid-cols-4 gap-5">
              <div>
                <p className="text-legavacBlue text-sm">Name</p>
                <p className="text-sm">mitali narkhede</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">Email</p>
                <p className="text-sm">mitali@gmail.com</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">Mobile</p>
                <p className="text-sm">897895674758967</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">Address</p>
                <p className="text-sm">mitali narkhede hjsdghg ghfgh dfhggh</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">Country</p>
                <p className="text-sm">India</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">City</p>
                <p className="text-sm">malkapur</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">District</p>
                <p className="text-sm">buldhana</p>
              </div>
              <div>
                <p className="text-legavacBlue text-sm">State</p>
                <p className="text-sm">maharashtra</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="font-Helvetica  flex justify-between pt-8">
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            className="font-Helvetica  py-2 px-5 text-sm rounded bg-legavacBlue text-white uppercase focus:outline-none hover:outline-none"
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            className="font-Helvetica  py-2 px-5 text-sm rounded bg-green-600 text-white uppercase"
            onClick={handleEdit}
          >
            Edit
          </button>
        </div>
      </div>
    </>
  );
}

export default MyProfile;
