import React from "react";
import "./App.css";
import Header from "./layout/Header";
import Home from "./pages/main/Home";
import Footer from "./layout/Footer";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./pages/main/ContactUs";
import CareerAdvice from "./pages/main/CareerAdvice";
import Job from "./pages/main/Job";
import About from "./pages/main/About";
import Profile from "./pages/main/Profile";
import AdvanceJobSearch from "./pages/sub/AdvanceJobSearch";
import ServiceWeOffer from "./pages/main/ServiceWeOffer";
import Article from "./pages/main/Article";
import Affilations from "./pages/main/Affilations";
import AppLayoutCandidate from "./components/AppLayoutCandidate";
import AppLayoutRecruiter from "./components/AppLayoutRecruiter";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/careeradvice" element={<CareerAdvice />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/jobs" element={<Job />} />
        <Route path="/advance-job-search" element={<AdvanceJobSearch />} />
        <Route path="/ServiceWeOffer" element={<ServiceWeOffer />} />
        <Route path="/article" element={<Article />} />
        <Route path="/affilation" element={<Affilations />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
