import React from "react";

const PrivacyPliicy = () => {
  return (
    <>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-5 relative lg:pt-28 pt-20">
        <div>
          <h3 className="text-3xl font-bold text-legavacBlue">
            Privacy Pliicy for Legal Detrum: Lawyers Database Mobile Application
          </h3>
        </div>
        <div className="pt-8">
          <p className="text-bold text-xl py-2 ">Effective Date: 27 Dec 2023</p>
          <p className="px-5">
            Welcome to Legal Detrum, the Lawyers Database mobile application
            provided by Legavac Resources Pvt Ltd ("we," "us," or "our"). This
            Privacy Pliicy is designed to inform both users and lawyers
            (clilectively referred to as "users") about how we clilect, use, and
            protect the personal information you provide through our mobile
            application.
          </p>
        </div>
        <div className="pt-10">
          <div>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              {" "}
              1.Information We Clilect:
            </p>
            <p className="text-xl  py-2">
              We may clilect various types of information, including:
            </p>
            <p className="text-legavacBlue font-semibold text-lg ">
              {" "}
              i. For Users:
            </p>
            <p>
              When you register for the Legal Detrum - World Lawyers Database,
              we may clilect personal information, including details such as
              your name, contact information, and preferences but not limited to
              your name, contact details, professional qualifications, and
              jurisdiction.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                a. Usage Data:
              </span>
              Information about your interactions with our mobile application.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                {" "}
                b. Device Information:{" "}
              </span>
              Technical details about the device you use to access our
              application.
            </p>
            <p className="text-legavacBlue font-semibold text-lg ">
              {" "}
              ii. For Lawyers:
            </p>
            <p>
              {" "}
              <span className="text-legavacYellow  font-bold">
                a. Professional Information:
              </span>{" "}
              We clilect information related to your legal practice, such as
              areas of expertise, bar association membership, and professional
              affiliations. Your credentials, areas of expertise, and other
              relevant professional details.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                b. Personal Information:{" "}
              </span>
              Information required for account creation and communication.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                c. Communication Data:
              </span>{" "}
              Information exchanged during one-to-one connections, including
              messages, emails, and any additional details shared vliuntarily by
              users.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              2. How We Use Your Information
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                a. Collection and Use of Information:
              </span>
              Information is collected directly from you during the registration
              process, profile creation, and one-to-one interactions within the
              Legal Detrum - World Lawyers Database platform. The information
              collected serves the following purposes, conducted in accordance
              with applicable data protection laws:
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                b. Providing and Enhancing Our Mobile Application:{" "}
              </span>
              The collected data is utilized to ensure the seamless provision of
              our mobile application, encompassing features for both users and
              lawyers.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                c. Personalizing User Experiences and Delivering Relevant
                Content:{" "}
              </span>
              We employ the gathered information to tailor user experiences,
              providing content that is relevant to the individual preferences
              and requirements of both users and lawyers.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                d. Facilitating Communication Between Users and Lawyers:{" "}
              </span>{" "}
              The information is used to facilitate effective communication
              within the Legal Detrum platform, fostering interactions between
              users and lawyers.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                e. Analyzing Usage Patterns and Trends to Improve Our Services:{" "}
              </span>{" "}
              In adherence to best practices, we engage in the analysis of usage
              patterns and trends. This analytical process is aimed at
              continuous improvement of our services, ensuring their alignment
              with the evolving needs of our user and lawyer communities. By
              engaging with the Legal Detrum platform, users and lawyers consent
              to the lawful collection and utilization of their information for
              the aforementioned purposes.
            </p>
            <p>
              <p className="text-xl font-bold py-2 text-legavacBlue">
                {" "}
                3. Purpose of Information Collection:
              </p>
              <p>
                <span className="text-legavacYellow  font-bold">
                  a. Database Integrity:{" "}
                </span>{" "}
                Your information is used to maintain the accuracy and integrity
                of the Legal Detrum - World’s largest Lawyers Database, ensuring
                a reliable resource for legal professionals worldwide.
              </p>
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                b. Networking:{" "}
              </span>{" "}
              Information collected is used to facilitate one-to-one
              connections, enabling legal professionals to connect and
              collaborate across borders.
            </p>
            <p>
              C. Communication: We may use your contact information to
              communicate with you about updates, new features, and relevant
              professional opportunities.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                d. Automated Technologies:{" "}
              </span>{" "}
              We may use cookies and similar technologies to collect information
              about your device, browsing actions, and patterns. This helps us
              improve our services and enhance user experience.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              4. Data Sharing and Third-Party Services{" "}
            </p>{" "}
            <p>
              Legal Detrum, owned by Legavac Resources Pvt Ltd, values
              transparency and is committed to safeguarding your privacy. To
              enhance the functionalities of our Lawyers Database mobile
              application, we may share information with third parties for
              specific purposes, including analytics and advertising.
              Third-party services integrated into our application, such as
              authentication services, analytics tools, and social media
              platforms, play a crucial role in enriching your overall
              experience. When you choose to utilize these third-party services
              through our application, it is important to note that you may be
              providing information directly to the third party, and such
              information may be subject to their respective privacy policies.
              We strongly encourage you to review the privacy policies of these
              third-party services to gain a comprehensive understanding of how
              your information will be handled. While we make reasonable efforts
              to ensure that the third-party services integrated into our
              application comply with applicable privacy laws and standards, it
              is crucial to acknowledge that we do not have control over the
              privacy practices of these third-party services. As such, we
              disclaim any responsibility for their actions or policies. If you
              access our Lawyers Database mobile application through a
              third-party platform or link, your usage is also governed by the
              terms and conditions and privacy policies of that third party. We
              recommend reviewing these documents before providing any personal
              information or using such services. We reserve the right to
              modify, add, or remove third-party services from our mobile
              application without prior notice. Any changes to the list of
              third-party services will be reflected in updates to this Privacy
              Policy, ensuring that you are kept informed of our data practices.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              5. Legal Compliance
            </p>
            <p>
              Legal Detrum, owned by Legavac Resources Pvt Ltd, is committed to
              ensuring compliance with all applicable laws and regulations
              governing the privacy and protection of personal information. Our
              privacy practices are designed to adhere to the requirements of
              data protection and other laws. As providers of a Lawyers Database
              mobile application, we understand the sensitivity of the
              information processed through our services. We take reasonable
              measures to safeguard the confidentiality, integrity, and
              availability of personal information in accordance with industry
              best practices. This Privacy Policy is periodically reviewed and
              updated to reflect changes in legal requirements, business
              practices, and the evolution of our services. Users are encouraged
              to review this Privacy Policy regularly for any updates. By
              continuing to use our Lawyers Database mobile application, you
              agree to the terms outlined in this Privacy Policy.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              6. User and Lawyer Control
            </p>
            <p>
              Legal Detrum, owned by Legavac Resources Pvt Ltd, holds a
              steadfast commitment to empowering users of the Lawyers Database
              mobile application with meaningful control over their personal
              information. As a user, you possess the following rights and
              options to manage your data:
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                {" "}
                a. Access and Correction:{" "}
              </span>{" "}
              You reserve the right to access the personal information we hold
              about you. In the event of inaccuracies or incompleteness, you may
              request corrections or updates to ensure the accuracy and
              relevance of your information.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                b. Data Portability:{" "}
              </span>{" "}
              At your discretion, you can request a copy of your personal
              information in a structured, commonly used, and machine-readable
              format for transfer to another data controller, where technically
              feasible.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                c. Withdrawal of Consent:{" "}
              </span>{" "}
              If our processing of certain personal information relies on your
              consent, you maintain the right to withdraw that consent at any
              time. It's essential to note that withdrawal does not impact the
              lawfulness of processing based on consent before its withdrawal.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                d. Account Deactivation:{" "}
              </span>{" "}
              You have the autonomy to deactivate your account or cease using
              our services at any time. However, deactivation does not
              automatically delete your personal information; please refer to
              our Data Retention policy for details on the retention period.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                {" "}
                e. Opt-Out of Communications:{" "}
              </span>{" "}
              You retain the choice to opt out of receiving promotional emails
              or other communications from us by following the opt-out
              instructions provided. It's important to note that even if you opt
              out, transactional communications regarding your account or our
              services may still be delivered. To exercise any of these rights
              or options, or if you have questions regarding your privacy
              rights, please contact us at [provide contact information]. We
              pledge to respond to your requests in adherence to applicable data
              protection laws. While our commitment is to provide you with
              control over your personal information, certain rights and options
              may be subject to legal and contractual restrictions. In instances
              where we cannot accommodate a specific request, we will
              communicate the reasons for denial. This User Control policy
              undergoes periodic review and may be updated to align with changes
              in legal requirements, industry standards, or our business
              practices. Both users and lawyers have rights to access, correct,
              and control their personal information. Deactivating your account
              or contacting us at info@legavac.com allows you to exercise these
              rights.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              7. Data Retention{" "}
            </p>
            <p>
              Legal Detrum, owned by Legavac Resources Pvt Ltd, upholds a data
              retention policy to ensure the responsible handling of personal
              information gathered through the Lawyers Database mobile
              application. The retention duration is aligned with the purposes
              stated in this Privacy Policy, unless extended by legal
              requirements. Inactive user accounts may undergo data deletion or
              anonymization at our discretion, sustaining the relevance and
              accuracy of stored information. Upon account termination or
              service discontinuation, personal data may be retained for a
              reasonable period to meet legal obligations, resolve disputes,
              enforce agreements, and for legitimate business purposes. To
              secure and protect your personal information during retention, we
              employ suitable technical and organizational measures. However, it
              is imperative to recognize that no data transmission or storage is
              entirely immune to risks. This Data Retention policy is subject to
              periodic review, ensuring alignment with evolving legal mandates,
              industry standards, and our business practices.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              8. Security
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                a. Data Security Measures:
              </span>
              Legal Detrum, owned by Legavac Resources Pvt Ltd, is dedicated to
              maintaining the utmost security of your personal information. We
              employ industry-standard security measures to safeguard against
              unauthorized access, disclosure, alteration, and destruction of
              the data collected through the Lawyers Database mobile
              application. These measures include robust encryption protocols,
              access controls, and regular security audits to ensure the
              integrity and confidentiality of your information.
            </p>
            <p>
              <span className="text-legavacYellow  font-bold">
                b. User Responsibility:
              </span>{" "}
              While we are committed to implementing reasonable and effective
              security measures, you, as a user, bear a responsibility in
              maintaining the confidentiality of your account credentials. It is
              crucial to exercise diligence in safeguarding your login
              information and adopting secure practices to prevent unauthorized
              access. We recommend that you refrain from sharing your account
              details and promptly notify us of any suspected or actual security
              breaches. By using the Legal Detrum platform, you acknowledge your
              role in upholding the security of your personal information and
              agree to comply with the outlined security practices.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              9. Changes to the Privacy Policy
            </p>
            <p>
              We reserve the right to update this Privacy Policy. Material
              changes will be communicated through the application or other
              means. Users and lawyers are encouraged to review the policy
              regularly. By using the World’s largest Lawyers Database, you
              acknowledge and agree to the terms outlined in this Privacy
              Policy. Legal Detrum, owned by Legavac Resources Pvt Ltd, reserves
              the right to update or modify this Privacy Policy at any time to
              reflect changes in legal requirements, industry standards, or the
              evolution of our services. When we make material changes to this
              Privacy Policy, we will notify users through a prominent notice
              within the Lawyers Database mobile application or via other means,
              such as email. Users are encouraged to review this Privacy Policy
              regularly for any updates. By continuing to use our services after
              the effective date of the revised Privacy Policy, you indicate
              your acceptance of the changes. If you do not agree with the terms
              of the updated Privacy Policy, you may choose to discontinue the
              use of our mobile application. It is the responsibility of users
              to ensure that they are familiar with the latest version of the
              Privacy Policy. The date of the last update is indicated at the
              top of this document. This Changes to the Privacy Policy is an
              integral part of the overall Privacy Policy and is subject to the
              same principles and conditions.
            </p>
            <p>
              10. Contact Us For inquiries or concerns regarding this Privacy
              Policy, contact us at info@legavac.com.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              11. Aggregate and Anonymous Data:{" "}
            </p>
            <p>
              We may collect, use, and disclose aggregate and anonymous data
              derived from the information provided by users of our Legal Detrum
              application owned by Legavac resources Pvt Ltd Lawyers Database
              service. Aggregate data refers to information that has been
              combined and anonymized to remove any personally identifiable
              details, rendering it incapable of identifying any specific
              individual. This aggregated and anonymized data may be utilized
              for statistical analysis, research, product improvement, and other
              lawful business purposes. By using our service, you acknowledge
              and agree that we may create and disclose such aggregate and
              anonymous data, which does not reveal the identity of any
              individual user or client. This data may be shared with third
              parties for various purposes, including but not limited to
              industry insights, trend analysis, and compliance with legal
              obligations. Rest assured, such data will not be re-identified or
              linked back to specific individuals.
            </p>
            <p className="text-xl font-bold py-2 text-legavacBlue">
              12. Advertising and Promotions:{" "}
            </p>
            <p>
              Our Lawyers Database mobile application may feature third-party
              advertisements and promotions from carefully selected partners.
              These advertisements may be displayed to you based on your usage
              patterns and preferences within the application. Please be aware
              that the information collected by these third-party advertisers is
              subject to their respective privacy policies, and we do not have
              control over their data collection practices. We may use targeted
              advertising to promote our services or third-party products and
              services that we believe may be of interest to you. This involves
              the use of non-personal information such as demographic data,
              device information, and usage patterns to deliver tailored
              advertising content. Rest assured that any personal information
              shared with advertisers will be in accordance with the terms of
              this Privacy Policy. It is important to note that while we strive
              to work with reputable partners, we are not responsible for the
              content or privacy practices of third-party advertisements. We
              encourage you to review the privacy policies of these third
              parties before engaging with their content or services.
            </p>
            <p>
              If you wish to opt out of targeted advertising within our mobile
              application, you can adjust your preferences in the application
              settings. However, please be aware that this opt-out may not apply
              to advertisements served by third parties outside of our control.
              This Privacy Policy is an integral part of your use of the Legal
              Detrum mobile application. By using our services, you agree to the
              terms outlined in this policy.
            </p>
            <div className="pt-5">
              <p className="text-3xl font-bold py-2 text-legavacBlue">
                Terms and Conditions:
              </p>
            </div>
            <p>
              <span className="text-legavacYellow font-bold">
                1. Acceptance of Terms:
              </span>{" "}
              By using the World Lawyers Database Mobile Application, users
              agree to comply with these terms and conditions.
            </p>
            <p>
              <span className="text-legavacYellow font-bold">
                2. User Responsibilities:{" "}
              </span>
              Users are responsible for maintaining the confidentiality of their
              account information and ensuring its accuracy.
            </p>
            <p>
              <span className="text-legavacYellow font-bold">
                3. Payment Terms:
              </span>{" "}
              All payments made through the application are subject to the terms
              and conditions of our selected payment gateway. Users agree to
              abide by these terms.
            </p>
            <p>
              <span className=" text-legavacYellow font-bold">
                4. Intellectual Property:
              </span>{" "}
              The content and features of the application are owned by Legavac
              Resources Pvt Ltd. Users may not reproduce or distribute the
              content without permission.
            </p>
            <p>
              <span className="text-legavacYellow font-bold">
                5. Limitation of Liability:
              </span>{" "}
              Legavac Resources Pvt Ltd is not liable for any damages arising
              from the use or inability to use the application. Thank you for
              entrusting Legal Detrum, the Lawyers Database application owned by
              Legavac Resources Pvt Ltd, with your personal information. Your
              privacy is of paramount importance to us, and we are committed to
              maintaining the confidentiality, integrity, and security of the
              data you share with us. This Privacy Policy serves as a
              foundational document outlining our data practices. By continuing
              to use the Legal Detrum mobile application, you acknowledge and
              consent to the terms set forth in this Privacy Policy. We
              encourage you to review this policy periodically, as updates may
              occur to reflect changes in legal requirements, industry
              standards, or our business practices. For any inquiries, concerns,
              or requests regarding your privacy rights or our data practices,
              please contact us at{" "}
              <span className="text-legavacBlue">info@legavac.com.</span> We are
              dedicated to addressing your concerns and ensuring the highest
              standards of privacy protection. Once again, thank you for
              choosing Legal Detrum. We appreciate the trust you place in us,
              and we remain committed to providing you with a secure and
              reliable platform for accessing legal information. This Privacy
              Policy is effective as of 27th Dec 2023, and any revisions will be
              clearly communicated through the application or other appropriate
              means.
            </p>
            <p className="py-5">
              {" "}
              <span className="font-bold">
                Legal Detrum Owned by Legavac Resources Pvt Ltd
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPliicy;
