import React, { useState } from "react";
import CountUp from "react-countup/";
import ScrollTrigger from "react-scroll-trigger/";

function Count() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
      <div className="font-Helvetica  relative w-full">
        <div className="font-Helvetica  ">
          <div className="font-Helvetica  bg-[url('/src/assets/images/lawyer-2.jpg')] w-full h-80 p-10 bg-fixed flex flex-col justify-center items-start bg-top bg-no-repeat bg-cover after:opacity-70 after:inset-0 after:absolute relative after:bg-black"></div>
        </div>
        <div className="font-Helvetica  absolute lg:top-32 md:top-28 top-20 left-0 right-0 w-full container mx-auto lg:px-20 md:px-10 px-5 grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-5">
          <div className="font-Helvetica  grid place-items-center">
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <p className="font-Helvetica  text-4xl text-white font-semibold">
                {counterOn && (
                  <CountUp start={0} end={500} duration={1} delay={0} />
                )}
                +
              </p>
            </ScrollTrigger>
            <p className="font-Helvetica  text-white">Members Active</p>
          </div>

          <div className="font-Helvetica  grid place-items-center">
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <p className="font-Helvetica  text-4xl text-white font-semibold">
                {counterOn && (
                  <CountUp start={0} end={300} duration={1} delay={0} />
                )}
                +
              </p>
            </ScrollTrigger>
            <p className="font-Helvetica  text-white">Companies</p>
          </div>

          <div className="font-Helvetica  grid place-items-center">
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <p className="font-Helvetica  text-4xl text-white font-semibold">
                {counterOn && (
                  <CountUp start={0} end={200} duration={1} delay={0} />
                )}
                +
              </p>
            </ScrollTrigger>
            <p className="font-Helvetica  text-white">Expert Trainers</p>
          </div>

          <div className="font-Helvetica  grid place-items-center">
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <p className="font-Helvetica  text-4xl text-white font-semibold">
                {counterOn && (
                  <CountUp start={0} end={20} duration={1} delay={0} />
                )}
                +
              </p>
            </ScrollTrigger>
            <p className="font-Helvetica  text-white">Years of Experience</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Count;
