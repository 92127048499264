import React from "react";
import { IMAGES } from "../../stores";

function About() {
  return (
    <>
      <div>
        <div className="font-Helvetica  lg:mx-auto md:container  md:mx-auto px-10 lg:px-20 md:px-10">
          <div className="font-Helvetica  grid lg:grid-cols-6  md:grid-cols-6  grid-cols-1  pt-20 gap-x-0 gap-y-10">
            <div className="font-Helvetica   col-span-6 lg:grid">
              <div className="font-Helvetica   lg:grid lg:grid-cols-6 md:grid-cols-1 grid-cols-1 md:flex flex lg:flex-row md:flex-col-reverse flex-col-reverse">
                <div className="font-Helvetica  lg:col-span-4 md:col-span-3 col-span-6">
                  {/* <div className="font-Helvetica  bg-slate-50">
                    <div className="font-Helvetica  lg:px-10 md:px-10 px-5 mb-5 pt-10">
                      <p className="font-Helvetica  text-2xl font-medium  mb-2">
                        About Our Group
                      </p>
                      <p className="font-Helvetica  text-3xl font-medium  tracking-wide">
                        The World Of{" "}
                        <span className="font-Helvetica  text-[#001ba1]">
                          <span className="font-Helvetica  text-[#eb8a00]">L</span>ega
                          <span className="font-Helvetica  text-[#eb8a00]">V</span>ac
                        </span>
                      </p>
                    </div>
                    <div className="font-Helvetica  ">
                      <div className="font-Helvetica space-y-5 lg:px-10 px-5 text-[16px]">
                        <p className="font-Helvetica">
                          <strong>LegaVac Resources Pvt Ltd</strong> Headquarter at <strong>Kharghar,
                          Navi Mumbai.</strong> <span><a href="" className="font-Helvetica  text-blue-600 underline">https://www.legavac.com</a></span> is the top most innovative
                          online job portal present in India. Founded in 2013
                          and converted into as a Private Limited Company since
                          2021. We at LegaVac Job Fair bring potential and
                          deserving candidates and top employers under one roof.
                        </p>
                        <p className="font-Helvetica">
                          LegaVac connects Law students, Lawyers and CS and
                          recruiters by accurately matching candidate profiles
                          to the relevant job openings through an advanced 2-way
                          matching technology. LegaVac works closely to bridge
                          the gap between talent & opportunities and offers
                          end-to-end recruitment solutions. LegaVac is changing
                          the way people think about work, and we're helping
                          them actively improve their lives and their workforce
                          performance with new technology, tools, and practices.
                        </p>
                      </div>
                    </div>

                    <div className="font-Helvetica   bg-white  space-y-5 lg:px-10 px-5 pt-4   text-[16px]">
                      <p className="font-Helvetica">
                        A LegaVac has a Purpose for betterment for the bright
                        future of the Law students and Lawyers and Company
                        secretary making it easy for them to find a desirable
                        career.
                      </p>
                      <p className="font-Helvetica">
                        A LegaVac Vision Create a bridge between Lawyers,
                        Company Secretary and Corporates.
                      </p>
                      <p className="font-Helvetica">
                        A LegaVac mission LegaVac creates and delivers the
                        best-recruiting media, technologies, and platforms for
                        connecting jobs and potential candidates. LegaVac
                        strives every day to help our top employers hire and
                        help people find job opportunities of their dreams.
                      </p>
                    </div>
                  </div> */}
                  <div className="font-Helvetica  bg-slate-50 p-2">
                    <div className="font-Helvetica  lg:px-10 md:px-10 px-2 mb-5 pt-5">
                      <p className="font-Helvetica  lg:text-3xl md:text-3xl text-2xl font-medium  mb-2">
                        About
                        <span className="font-Helvetica  text-[#001ba1] px-2">
                          <span className="font-Helvetica  text-[#eb8a00]">
                            L
                          </span>
                          ega
                          <span className="font-Helvetica  text-[#eb8a00]">
                            V
                          </span>
                          ac
                        </span>
                        Services Private Limited
                      </p>
                    </div>
                    <div className="font-Helvetica  ">
                      <div className="font-Helvetica space-y-5 lg:px-10 px-5 text-[16px]">
                        <p className="font-Helvetica">
                          <strong>LegaVac Resources Pvt Ltd</strong> Headquarter
                          at <strong>Kharghar, Navi Mumbai.</strong>{" "}
                        </p>
                        <p className="font-Helvetica px-5">
                          Welcome to LegaVac Services Private Limited, your
                          trusted partner in legal solutions. Established with a
                          commitment to excellence, LegaVac is a leading
                          provider of comprehensive legal services in India. We
                          strive to empower individuals and businesses through
                          our specialized offerings three services FIR
                          Registration , Litigation Management and Legal Detrum
                          - World largest Lawyers Network. Our Commitment At
                          LegaVac Services Private Limited, we are driven by a
                          commitment to integrity, transparency, and client
                          satisfaction. We believe in making legal processes
                          accessible and efficient, ensuring that our clients
                          receive the highest quality of service. With a
                          customer-centric approach, we aim to redefine the
                          standards of legal assistance in India.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font-Helvetica  lg:col-span-2 md:col-span-3 col-span-6 grid lg:place-items-start md:place-items-center place-items-center">
                  <img
                    src={IMAGES.AboutUs}
                    className="font-Helvetica  lg:h-full lg:w-fit md:w-fit md:h-full  opacity-80"
                  />
                </div>
              </div>
            </div>
            {/* <div className="font-Helvetica   col-span-6 lg:grid">
              <div className="font-Helvetica  lg:col-span-6 md:col-span-6 grid lg:grid-cols-6 md:grid-cols-1 grid-cols-1 ">
                
                <div className="font-Helvetica  lg:col-span-2 md:col-span-3 col-span-6 grid lg:place-items-start md:place-items-start place-items-center">
                  <img
                    src={IMAGES.AboutUs2}
                   
                    className="font-Helvetica  lg:h-full lg:w-fit md:w-fit md:h-full   opacity-80"
                  />
                </div>
                <div className="font-Helvetica  lg:col-span-4 md:col-span-3 space-y-5">
                  <div className="font-Helvetica  lg:px-20 md:px-10 px-5 pt-10 ">
                    <p className="font-Helvetica  text-2xl font-medium mb-2">
                      About Our Group
                    </p>
                    <p className="font-Helvetica  text-3xl font-medium   mb-5 tracking-wider">
                      Who We Are
                    </p>

                    <div className="font-Helvetica  space-y-5">
                      <p className="font-Helvetica  text-[16px]">
                        We at LegaVac are a specialist division with{" "}
                        <strong>10+ years</strong> industry experience in
                        developing talent strategies and managed solutions to
                        optimize also Pharmaceutical Company, Finance,
                        Accounting and Legal functions in organisations across
                        industry sectors.
                      </p>
                    </div>
                  </div>
                  <div className="font-Helvetica  bg-slate-50">
                    <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1  lg:px-24 lg:p-10 md:p-10 pl-10 py-5 font-semibold">
                      <div className="font-Helvetica  col-span-1">
                        <ul className="font-Helvetica   list-disc marker:text-[#eb8a00] marker:text-2xl">
                          <li className="font-Helvetica">Corporate Finance</li>
                          <li className="font-Helvetica">
                            Ledgers & Bookkeeping{" "}
                          </li>
                          <li className="font-Helvetica">Credit Control</li>
                          <li className="font-Helvetica">Payroll & Tax</li>
                          <li className="font-Helvetica">Audit & Advisory</li>
                          <li className="font-Helvetica">
                            Recovery & Restructure
                          </li>
                          <li className="font-Helvetica">
                            {" "}
                            Interim Management
                          </li>
                          <li className="font-Helvetica">Secretarial</li>
                          <li className="font-Helvetica">
                            Senior Statistician.{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="font-Helvetica  col-span-1">
                        <ul className="font-Helvetica  list-disc  marker:text-[#eb8a00] marker:text-2xl">
                          <li className="font-Helvetica">
                            {" "}
                            Drug Safety Specialist.
                          </li>
                          <li className="font-Helvetica">Senior QA Engineer</li>
                          <li className="font-Helvetica">
                            {" "}
                            Pharmaceutical Financial Analyst
                          </li>
                          <li className="font-Helvetica">Pharmacist</li>
                          <li className="font-Helvetica">
                            Biotechnology and Senior Scientist
                          </li>
                          <li className="font-Helvetica">
                            {" "}
                            Pharmaceutical Sales Engineer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* // why choose us */}
            {/* <div className="font-Helvetica  lg:col-span-6 md:col-span-6">
              <p className="font-Helvetica  text-2xl font-medium mb-2">
                Why Choose Us
              </p>
              <p className="font-Helvetica  text-3xl font-medium  mb-5 tracking-wider">
                The Advantage We delivers
              </p>

              <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 text-white">
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.logistics} />
                  <p className="font-Helvetica  p-5">
                    End-to-End solution to outsource Finance and Legal functions
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.accuracy} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Tailored talent solution with industry focus{" "}
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.management} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Good network with qualified professionals in Legal and
                    Finance domains{" "}
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.teamwork} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Experienced team with proven track record of PAN-India
                    delivery{" "}
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.vacancy} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Speedy recruitment process at all levels of hiring{" "}
                  </p>
                </div>

                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.job} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Thorough screening of candidates to ensure quality
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.humanresources} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Tech-enabled recruitment process with end-to-end automation
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.development} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Economic and efficient - Save administrative costs and
                    achieve process excellence
                  </p>
                </div>

                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.analysis} />

                  <p className="font-Helvetica  p-5">
                    {" "}
                    Performance-driven execution with measurable and sustainable
                    business results{" "}
                  </p>
                </div>

                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.workflow} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Inhouse tech support to manage Time sheet, Workflow, etc.
                  </p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.dashboard} />
                  <p className="font-Helvetica  p-5">
                    {" "}
                    Real-time reports & dashboards to map efficiency, resource
                    requirements, payouts etc.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="">
            <p className=" py-5 text-3xl font-medium   mb-5 tracking-wider">
              Leadership and Team
            </p>
            <div className="grid lg:grid-cols-2  md:grid-cols-1  grid-cols-1 pt-5 pb-10 gap-x-0 gap-y-5 place-content-center">
              <div className="flex justify-center lg:px-10 md:px-10">
                <img src={IMAGES.SurabhaSir} className="" />
              </div>
             
              <div className="pb-5 lg:px-10 md:px-10 ">
                <p className="text-xl text-legavacBlue font-bold py-2">
                  Adv. Mr.Saurabh Dhage
                </p>
           
                <p>
                  Adv. Saurabh Dhage is a seasoned legal professional with 12
                  years of extensive litigation practice, specializing in
                  compliance consultancy. His commitment to legal excellence is
                  underscored by his honorary role as the E-filing District
                  Coordinator for Buldhana District, appointed by the Bar
                  Council of Maharashtra and Goa and affiliated with the Hon’ble
                  High Court of Mumbai. Additionally, Adv. Dhage plays a pivotal
                  role in fostering legal education and networking as he holds
                  the esteemed position of Chairmanship in the Alumni
                  Association of Modern Law College, Pune. His rich experience,
                  coupled with a robust network, positions him as a respected
                  figure in the legal community.
                </p>
              </div>
              {/* <div className="pb-5 lg:px-10 md:px-10">
                <p className="text-xl text-legavacBlue font-bold py-2">
                  Mr.Sunil Ramrao Shelke
                </p>
                <p>
                  Sunil Ramrao Shelke, a seasoned professional, served with
                  distinction as a Sub Divisional Officer and Officer on Special
                  Duty in the Revenue Department of the Maharashtra Government
                  Mantralaya. Throughout his illustrious career, Mr. Shelke
                  demonstrated a keen understanding of administrative
                  intricacies, contributing significantly to the efficient
                  functioning of the department. His commitment to public
                  service, combined with strategic leadership, has left a
                  lasting impact on the realms of revenue management and
                  administration in Maharashtra.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
