import React, { useState, useEffect } from "react";
import ChannelSourcing from "../sub/services/ChannelSourcing";
import RecruitingWorkflow from "../sub/services/RecruitingWorkflow";
import RecruitmentPlatform from "../sub/services/RecruitmentPlatform";
import VendorManagement from "../sub/services/VendorManagement";
import WorkFlowSolution from "../sub/services/WorkFlowSolution";
import Products from "../../components/Products";
import ServiceContent from "../../components/ServiceContent";

function ServiceWeOffer() {
  return (
    <>
      <div>
        {/* <RecruitmentPlatform />
        <WorkFlowSolution />
        <VendorManagement />
      <ChannelSourcing />
      <RecruitingWorkflow /> */}
        <ServiceContent />
      <Products />
      </div>
    </>
  );
}

export default ServiceWeOffer;
