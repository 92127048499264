import React, { useEffect, useState } from "react";
import { IMAGES } from "../stores";
import { MdOutlineWatchLater, MdOutlineLocationOn, MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TOAST_PROPERTIES } from "../stores/ToastProperties";
import Toster from "../stores/Toster";
import Button from "../stores/Button";

function JobListing() {
  const navigate = useNavigate();

  const list = [
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
    {
      companyLogo: IMAGES.CompanyLogo,
      companyName: "Company Name",
      job: "Job Name",
      position: "Job Position",
      duration: "2 year ago",
      city: "Pune",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    },
  ];
  return (
    <>
      <div className="font-Helvetica  bg-slate-50 ">
        <div className="font-Helvetica  container mx-auto w-full lg:px-20 md:px-10 px-5 py-10 ">
          <div className="font-Helvetica  pb-10">
            <p className="font-Helvetica  text-3xl font-semibold text-center">Job Listing</p>
          </div>
          <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
            {list?.map((l, idx) => (
              <div
                className="font-Helvetica  job-listing bg-white border hover:border-legavacBlue hover:shadow-2xl hover:shadow-slate-300 rounded"
                Key={idx}
              >
                <JobListingBlog {...l} />
              </div>
            ))}
          </div>
          <div className="font-Helvetica  pt-10 flex justify-center">
            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              className="font-Helvetica  bg-legavacYellow text-white font-semibold tracking-wide px-5 py-2 rounded transition duration-150 ease-in-out"
              onClick={() => navigate("/advance-job-search")}
            >
              More Listing
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function JobListingBlog({
  companyLogo,
  companyName,
  job,
  duration,
  desc,
  city,
}) {
  const [list, setList] = useState([]);
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "success") {
      ToastProperties = {
        id: list.length + 1,
        title: "Successfull",
        icon:"MdCheck",
        description: "You have succesfully applied for Job",
        backgroundColor: "#cefad0",
        borderColor:"#005C29",
        color:"#005C29"
      };
      setList([...list, ToastProperties]);
    }
  };
  return (
    <>
      <div className="relative">
        <div className=" p-5">
          <div className="flex md:space-x-7 space-x-5 items-center">
            <div className="company-logo grid place-items-center space-y-1">
              <img src={companyLogo} alt="" className=" w-12 h-12" />
            </div>
            <div className="font-Helvetica  space-y-1">
              <p className="font-Helvetica  text-sm font-medium text-gray-400">{city}</p>
              <p className="font-Helvetica  text-xl text-legavacBlue font-semibold truncate">
                {companyName}
              </p>
            </div>
          </div>
        </div>

        <div className="font-Helvetica  flex space-x-2 p-5 items-center border-t">
          <div className="font-Helvetica  space-y-5">
            <p className="font-Helvetica  text-xl font-semibold">{job}</p>
            <p className="font-Helvetica  text-gray-500 text-sm">{desc}</p>
            <div className="font-Helvetica  flex justify-between items-center">
              <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineWatchLater size={15} color="gray" />
                <p className="font-Helvetica  text-sm text-gray-500">{duration}</p>
              </div>
              <div>
                <Button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="bg-legavacBlue py-1 px-5 text-white font-medium text-sm rounded transition duration-150 ease-in-out"
                  handleClick={() => showToaster("success")}
                >
                  Apply
                </Button>
              </div>
              <Toster
                toastList={list}
                setList={setList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JobListing;
