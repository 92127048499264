import React, { useState } from "react";

function ChangePassword() {
  const password = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [state, setState] = useState(password);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const update = () => {
    alert("Update Successfully")
  }

  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Change Password
          </p>
        </div>

        <div className="font-Helvetica  pt-8 space-y-5">
            <div className="font-Helvetica  grid grid-cols-1">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Old Password :
                </p>
                <input
                  type="text"
                  placeholder="Enter old password"
                  id="oldPassword"
                  name="oldPassword"
                  value={state.oldPassword}
                  onChange={handleChange}
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
            </div>
            <div className="font-Helvetica  grid grid-cols-2 gap-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  New Password :
                </p>
                <input
                  type="text"
                  placeholder="Enter mobile no"
                  id="newPassword"
                  name="newPassword"
                  value={state.newPassword}
                  onChange={handleChange}
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Confirm Password :
                </p>
                <input
                  type="text"
                  placeholder="Enter mobile no"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={state.confirmPassword}
                  onChange={handleChange}
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between pt-5">
              <div className="font-Helvetica  ">
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica  text-sm bg-legavacBlue font-medium uppercase rounded text-white py-2 px-5 tracking-wide cursor-pointer transition duration-150 ease-in-out"
                  onClick={update}
                >
                  Update
                </button>
              </div>
              <div className="font-Helvetica  ">
                <button
                  type="reset"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica  text-sm bg-legavacYellow font-medium uppercase rounded text-white py-2 px-5 tracking-wide cursor-pointer transition duration-150 ease-in-out"
                  onClick={()=>{setState({ oldPassword: "",newPassword:"", confirmPassword:"" });}}
                >
                  Reset
                </button>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
