import React from "react";
import { MdOutlineHomeWork } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { HiOutlineAcademicCap, HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { SiMinds } from "react-icons/si";

function MoreInfo() {
  const info = [
    {
      icon: <RiUserSettingsLine />,
      title: "Job Type",
      name: "Permanant",
    },
    {
      icon: <MdOutlineHomeWork />,
      title: "Work Mode",
      name: "Work from Office",
    },
    {
      icon: <HiOutlineBuildingOffice2 />,
      title: "Industry",
      name: "IT / Software Company",
    },
  ];
  const skill = [
    {
      skill: "React Js",
    },
    {
      skill: "Bootstrap 5",
    },
    {
      skill: "Tailwind Css",
    },
    {
      skill: "JavaScript",
    },
  ];
  const education = [
    {
      education: "Bachelor of Technology (B.Tech)",
    },
    {
      education: "Bachelor of Computer Application (BCA)",
    },
    {
      education: "Master of Computer Application (MCA)",
    },
  ];

  return (
    <>
      <div className="font-Helvetica  space-y-3">
        <p className="font-Helvetica  text-black font-semibold">More Information</p>
        {info?.map((m, idx) => (
          <div className="font-Helvetica  " key={idx}>
            <MoreInfoBlog {...m} />
          </div>
        ))}
        <div className="font-Helvetica  space-y-2 pt-2">
         <div className="font-Helvetica  flex space-x-2 items-center">
          <SiMinds />
          <p className="font-Helvetica  text-slate-500 text-xs uppercase">Skills</p>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-start">
          <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2 pl-6">
         
            {skill?.map((s, idx) => (
              <p
                className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
                key={idx}
              >
                {s.skill}
              </p>
            ))}
          </div>
        </div>
        </div>
        <div className="font-Helvetica  space-y-2 pt-2">
         <div className="font-Helvetica  flex space-x-2 items-center">
          <HiOutlineAcademicCap />
          <p className="font-Helvetica  text-slate-500 text-xs uppercase">Education</p>
        </div>
        <div className="font-Helvetica  space-y-1 items-start pl-6">
         
            {education?.map((edu, idx) => (
              <p
                className="font-Helvetica  text-sm text-black"
                key={idx}
              >
                {edu.education}
              </p>
            ))}
        </div>
        </div>
      </div>
    </>
  );
}

function MoreInfoBlog({ icon, title, name }) {
  return (
    <>
      <div className="font-Helvetica  ">
        <div className="font-Helvetica  flex space-x-2 items-center">
          {icon}
          <p className="font-Helvetica  text-slate-500 text-xs uppercase">{title}</p>
        </div>
        <div className="font-Helvetica  flex space-x-1">
          <p className="font-Helvetica  text-sm text-black pl-6">{name}</p>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
