import React from 'react'
import { Outlet } from 'react-router-dom'
import RecruiterHeader from '../layout/RecruiterHeader'

function AppLayoutRecruiter() {
  return (
    <>
    <RecruiterHeader/>
    <Outlet/>
    </>
  )
}

export default AppLayoutRecruiter