import React from "react";
import { MdDelete } from "react-icons/md";

function SavedJobs() {
  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
           10 Saved Jobs
          </p>
        </div>
        <div className="font-Helvetica  pt-8">
          <div className="font-Helvetica  flex flex-col">
            <div className="font-Helvetica  overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="font-Helvetica  py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="font-Helvetica  overflow-hidden">
                  <table className="font-Helvetica  min-w-full">
                    <thead className="font-Helvetica  bg-blue-100">
                      <tr>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Jobs
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="font-Helvetica  border-b items-center">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Social Media Expert
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          1 Jan 2023
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-slate-100 border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Web Designer
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          2 Feb 2023
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-white border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Advocate
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          23 Jan 2022
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-slate-100 border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Social Media Expert
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          1 Jan 2023
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-white border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Web Designer
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          2 Feb 2023
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-slate-100 border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Advocate
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          23 Jan 2022
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-white border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Advocate
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          23 Jan 2022
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                      <tr className="font-Helvetica  bg-slate-100 border-b">
                        <td className="font-Helvetica  px-6 py-4 whitespace-nowrap text-sm font-medium hover:underline">
                          Social Media Expert
                        </td>
                        <td className="font-Helvetica  text-sm text-blue-500 hover:text-blue-600 hover:underline font-light px-6 py-4 whitespace-nowrap">
                          Company Name
                        </td>
                        <td className="font-Helvetica  text-sm text-slate-600 font-light px-6 py-4 whitespace-nowrap">
                          1 Jan 2023
                        </td>
                        <td className="font-Helvetica  flex space-x-5 px-6 py-4">
                          <MdDelete size={20} className="font-Helvetica  text-red-500" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedJobs;
