import React from "react";
import { MdFileDownload } from "react-icons/md";
import { IMAGES } from "../../stores";

function Affilations() {
  return (
    <>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-2">
        <div className="font-Helvetica  pt-20  space-y-5">
          <div className="font-Helvetica  bg-slate-50 mt-10">
            <p className="font-Helvetica  text-3xl text-center tracking-wide  text-legavacBlue font-semibold p-5">Affilations </p>
          </div>
          <div className="font-Helvetica  bg-slate-50 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 lg:p-5 md:p-5 p-0">
            <div className="font-Helvetica  ">
              
              <div className="font-Helvetica  border shadow-lg rounded">
                <img
                  src={IMAGES.article}
                  alt="affiliation1"
                  className="font-Helvetica  w-80 h-80"
                />
              </div>
              <div className="font-Helvetica  pl-2 pt-2">
                <p className="font-Helvetica  ">File Name</p>
                <div className="font-Helvetica  flex space-x-2 items-center">
                  <p className="font-Helvetica  text-legavacBlue font-semibold">
                    <a href="/">View File</a>
                  </p>
                  <MdFileDownload className="font-Helvetica  text-legavacBlue" />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  ">
              
              <div className="font-Helvetica  border shadow-lg rounded">
                <img
                  src={IMAGES.article2}
                  alt="affiliation1"
                  className="font-Helvetica  w-80 h-80"
                />
              </div>
              <div className="font-Helvetica  pl-2 pt-2">
                <p className="font-Helvetica  ">File Name</p>
                <div className="font-Helvetica  flex space-x-2 items-center">
                  <p className="font-Helvetica  text-legavacBlue font-semibold">
                    <a href="/">View File</a>
                  </p>
                  <MdFileDownload className="font-Helvetica  text-legavacBlue" />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  ">
              
              <div className="font-Helvetica  border shadow-lg rounded">
                <img
                  src={IMAGES.article3}
                  alt="affiliation1"
                  className="font-Helvetica  w-80 h-80"
                />
              </div>
              <div className="font-Helvetica  pl-2 pt-2">
                <p className="font-Helvetica  ">File Name</p>
                <div className="font-Helvetica  flex space-x-2 items-center">
                  <p className="font-Helvetica  text-legavacBlue font-semibold">
                    <a href="/">View File</a>
                  </p>
                  <MdFileDownload className="font-Helvetica  text-legavacBlue" />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  ">
              
              <div className="font-Helvetica  border shadow-lg rounded">
                <img
                  src={IMAGES.article4}
                  alt="affiliation1"
                  className="font-Helvetica  w-80 h-80"
                />
              </div>
              <div className="font-Helvetica  pl-2 pt-2">
                <p className="font-Helvetica  ">File Name</p>
                <div className="font-Helvetica  flex space-x-2 items-center">
                  <p className="font-Helvetica  text-legavacBlue font-semibold">
                    <a href="/">View File</a>
                  </p>
                  <MdFileDownload className="font-Helvetica  text-legavacBlue" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Affilations;
