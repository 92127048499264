import React from "react";
import { MdOutlineLocationOn, MdOutlineRemoveRedEye } from "react-icons/md";
import { VscBriefcase } from "react-icons/vsc";
import {FiSend} from "react-icons/fi";

function Highlights() {
  return (
    <>
      <div className="font-Helvetica  space-y-1">
        <div className="font-Helvetica  flex space-x-2 items-center">
          <MdOutlineLocationOn size={20} className="font-Helvetica  text-slate-500" />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">Pune,</p>
            <p className="font-Helvetica  text-slate-500 text-xs">India</p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-center">
          <VscBriefcase size={20} className="font-Helvetica  text-slate-500" />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">2 - 4 years</p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-5">
            <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineRemoveRedEye size={20} className="font-Helvetica  text-slate-500" />
                <p className="font-Helvetica  text-slate-500 text-xs">200</p>
            </div>
            <div className="font-Helvetica  flex space-x-2 items-center">
                <FiSend size={18} className="font-Helvetica  text-slate-500 rotate-45" />
                <p className="font-Helvetica  text-slate-500 text-xs">500 applied</p>
            </div>
        </div>
      </div>
    </>
  );
}

export default Highlights;
