import React, { useState } from "react";
import { MdClose, MdMenu, MdOutlineSettings, MdPhone } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../stores";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BiHome } from "react-icons/bi";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { Offcanvas, Ripple, Dropdown, initTE } from "tw-elements";

function Header() {
  initTE({ Offcanvas, Ripple, Dropdown });
  const [color, setColor] = useState(false);
  const [iconColor, setIconColor] = useState(false);

  const navigate = useNavigate();

  const btnLogout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };

  const [show, setShow] = useState(false);
  const funShow = () => {
    setShow(true);
  };
  const funHide = () => {
    setShow(false);
  };
  return (
    <>
      <div className="font-Helvetica  fixed top-0 z-50 w-full bg-white text-white p-10 transition-all ease-in-out duration-200 shadow-md shadow-slate-200">
        <div className="font-Helvetica  absolute top-0 left-0 right-0 py-5 container w-full lg:mx-auto md:mx-auto mx-auto flex justify-between  lg:px-20 md:px-10 px-5 text-white">
          <div className="font-Helvetica  flex items-center justify-between w-full lg:space-x-10">
            <div className="font-Helvetica  ">
              <Link to="/">
                <img src={IMAGES.MainLogo} className="font-Helvetica  w-32" />
              </Link>
            </div>

            <div className="font-Helvetica  font-quickStand lg:block md:hidden hidden">
              <ul className={color ? " color color-active" : "color"}>
                <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/" className="font-Helvetica">
                    Home
                  </a>
                </li>
                <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/about-us" className="font-Helvetica">
                    About Us
                  </a>
                </li>

                <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/ServiceWeOffer" className="font-Helvetica">
                    Services
                  </a>
                </li>

                <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/article" className="font-Helvetica">
                    Articles
                  </a>
                </li>
                <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a
                    href="https://wa.link/c8eumi"
                    target="_blank"
                    className="font-Helvetica"
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/jobs" className="font-Helvetica">
                    Jobs
                  </a>
                </li> */}

                {/* <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-semibold text-md">
                        More
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/article"
                        >
                          Article
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="/affilation"
                        >
                          Affilation
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="/careeradvice"
                        >
                          Career Advice
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>

          <div
            className="font-Helvetica space-x-1 items-center pt-2 lg:hidden md:block transition-all ease-in-out duration-700"
            data-te-offcanvas-toggle
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            <MdMenu
              size={30}
              className={
                iconColor ? "icon-color icon-color-active" : "icon-color"
              }
              onClick={funShow}
            />
          </div>
        </div>
      </div>

      {/* Responsive Header on Mobile view */}
      {show ? (
        <div
          // class="invisible fixed bottom-0 left-0 top-0 z-[1045] flex w-96 max-w-full -translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"

          className="font-Helvetica  fixed bottom-0 z-[1045] p-5 flex flex-col md:w-80 w-80 bg-[#000322]
        bg-clip-padding shadow-sm outline-none transition duration-700 ease-in-out text-gray-700 top-0 
        left-0 border-none max-w-full translate-x overflow-y-auto [&[data-te-offcanvas-show]]:transform"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
          data-te-offcanvas-init
        >
          <div className="font-Helvetica  flex justify-between items-center">
            <Link to="/">
              <img
                src={IMAGES.WhiteLogo}
                alt=""
                className="font-Helvetica  w-28 shadow shadow-white p-2 cursor-pointer"
              />
            </Link>
            <MdClose
              size={20}
              color="white"
              data-te-offcanvas-dismiss
              onClick={funHide}
            />
          </div>

          <div className="font-Helvetica py-10">
            <ul className="font-Helvetica  divide-y-[1px] divide-slate-700 px-2">
              <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                <a
                  href="/"
                  className="font-Helvetica  flex space-x-2 items-center"
                >
                  <BiHome size={20} />
                  <p>Home</p>
                </a>
              </li>
              <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                <a
                  href="/about-us"
                  className="font-Helvetica  flex space-x-2 items-center"
                >
                  <IoMdInformationCircleOutline size={20} />
                  <p>About Us</p>
                </a>
              </li>
              <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                <a
                  href="/ServiceWeOffer"
                  className="font-Helvetica  flex space-x-2 items-center"
                >
                  <MdOutlineSettings size={20} />
                  <p>Services</p>
                </a>
              </li>
              <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                <a
                  href="/article"
                  className="font-Helvetica  flex space-x-2 items-center"
                >
                  <MdOutlineStickyNote2 size={20} />
                  <p>Articles</p>
                </a>
              </li>
              <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                <a
                  href="https://wa.link/755bet"
                  target="_blank"
                  className="font-Helvetica  flex space-x-2 items-center"
                >
                  <MdPhone size={20} />
                  <p>Contact Us</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Header;
