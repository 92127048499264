import React, { useState } from "react";
import { VscAccount, VscBriefcase, VscHeart, VscKey } from "react-icons/vsc";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  MdMailOutline,
  MdOutlineFileUpload,
  MdOutlineLogout,
  MdOutlineManageAccounts,
  MdOutlinePhone,
  MdPhotoCamera,
} from "react-icons/md";
import { IMAGES } from "../../stores";
import MyProfile from "../sub/profile/MyProfile";
import SavedJobs from "../sub/profile/SavedJobs";
import AppliedJobs from "../sub/profile/AppliedJobs";
import ChangePassword from "../sub/profile/ChangePassword";
import { useNavigate } from "react-router-dom";
import MyResume from "../sub/profile/MyResume";
import { HiOutlineLink } from "react-icons/hi2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CreateJobAlert from "../sub/profile/CreateJobAlert";

function Profile() {
  let navigate = useNavigate();
  const btnLogout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };

  const percentage = 60;
  const value = 0.66;

  <CircularProgressbar value={percentage} text={`${percentage}%`} />;

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  return (
    <>
      <div className="font-Helvetica  py-20 relative">
        <div className="font-Helvetica  lg:block md:block hidden w-full">
          <div className="font-Helvetica  bg-[#000322] pt-10 pb-20">
            <div className="font-Helvetica  container mx-auto lg:px-28 md:px-0 w-full">
              <div className="font-Helvetica  flex justify-between items-center lg:p-10 md:py-10">
                <div className="font-Helvetica  flex space-x-10 items-center">
                  <div className="font-Helvetica  relative">
                    <div className="font-Helvetica  border rounded border-white w-40 h-40 p-1">
                      {/* <img
                        src={IMAGES.Profile}
                        alt=""
                        className="font-Helvetica  w-40 h-40 rounded"
                      /> */}
                      {file && (
                        <div id="selected-image">
                          <img
                            src={file}
                            alt="Selected image"
                            className="w-[160px] h-[150px] rounded"
                          />
                        </div>
                      )}
                    </div>
                    <label
                      className="font-Helvetica  absolute -bottom-3 -right-3 rounded-full bg-white p-1 border border-legavacYellow"
                      // type="file"
                      for="file_icon"
                    >
                      <MdPhotoCamera
                        size={25}
                        className="font-Helvetica  text-legavacBlue"
                      />
                      <input
                        type="file"
                        id="file_icon"
                        onChange={handleFileChange}
                        className="text-sm hidden"
                      />
                    </label>
                  </div>
                  <div className="font-Helvetica  space-y-3">
                    <div>
                      <p className="font-Helvetica  text-white text-2xl font-semibold">
                        John Lewis
                      </p>
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdOutlineManageAccounts className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium">
                          Advocate
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <VscBriefcase className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium ">
                          0 yrs Experience
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdOutlinePhone className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium ">
                          +91 9813245670
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdMailOutline className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium">
                          example@gmail.com
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2 items-center">
                        <HiOutlineLink className="font-Helvetica  text-white " />
                        <a className="font-Helvetica  text-white text-sm font-medium cursor-pointer">
                          https://www.google.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="font-Helvetica  flex space-x-10 items-center">
                  <CircularProgressbar
                    className="font-Helvetica  CircularProgressbar"
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                  />
                  <div className="font-Helvetica  ">
                    <p className="font-Helvetica  text-lg text-white font-semibold text-center">
                      Profile is completed in 66%
                    </p>
                    <p className="font-Helvetica  text-xs text-white opacity-80 text-center">
                      Want to get best job ?
                    </p>
                    <p className="font-Helvetica  text-xs text-white text-center">
                      <span className="font-Helvetica  text-red-500">
                        {" "}
                        Boost your profile
                      </span>{" "}
                      by suggested below
                    </p>
                    <div className="font-Helvetica   grid place-items-center pt-3">
                      <label
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        className="font-Helvetica text-center flex space-x-2 items-center text-sm cursor-pointer bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-semibold rounded py-2 px-5 transition duration-150 ease-in-out"
                        for="file_input"
                      >
                        <MdOutlineFileUpload size={20} />{" "}
                        <p className="font-Helvetica">Upload Resume</p>
                      </label>
                      <input
                        class="w-full hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="file_input"
                        type="file"
                        onChange={handleFileUpload}
                      />
                      {file && (
                        <div className="py-1">
                          <p className="text-white text-xs cursor-pointer">{file.name}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="font-Helvetica  relative container mx-auto lg:px-20 md:px-10 px-2 pt-12 w-full">
          <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-1 grid-cols-1 lg:gap-7 md:gap-y-7 gap-y-7 items-start w-full">
            <div className="font-Helvetica  grid place-items-center bg-white rounded shadow-lg shadow-slate-300 border border-slate-100">
              <ul
                className="font-Helvetica  flex flex-col divide-y w-full flex-wrap list-none border-b-0 pl-0"
                role="tablist"
                data-te-nav-ref
              >
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-myProfileVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm active 
                        border-l-[3px] border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100
                     hover:bg-gray-100 focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25
                      data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-myProfileVertical"
                    role="tab"
                    data-te-nav-active
                    aria-controls="tabs-myProfileVertical"
                    aria-selected="true"
                  >
                    <VscAccount size={20} />
                    <p className="font-Helvetica"> My Profile</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-myResumeVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-myResumeVertical"
                    role="tab"
                    aria-controls="tabs-myResumeVertical"
                    aria-selected="false"
                  >
                    <IoDocumentTextOutline size={20} />
                    <p className="font-Helvetica">My Resume</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-savedJobsVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-savedJobsVertical"
                    role="tab"
                    aria-controls="tabs-savedJobsVertical"
                    aria-selected="false"
                  >
                    <VscHeart size={20} />
                    <p className="font-Helvetica">Saved Jobs</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-appliedJobsVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-appliedJobsVertical"
                    role="tab"
                    aria-controls="tabs-appliedJobsVertical"
                    aria-selected="false"
                  >
                    <VscBriefcase size={20} />
                    <p className="font-Helvetica">Applied Jobs</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-createJobAlertVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-createJobAlertVertical"
                    role="tab"
                    aria-controls="tabs-createJobAlertVertical"
                    aria-selected="false"
                  >
                    <IoMdNotificationsOutline size={25} />
                    <p className="font-Helvetica">Create Job Alert</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-changePassVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-changePassVertical"
                    role="tab"
                    aria-controls="tabs-changePassVertical"
                    aria-selected="false"
                  >
                    <VscKey size={20} />
                    <p className="font-Helvetica">Change Password</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="#tabs-logoutVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100 
                  focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25 data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary "
                    data-te-toggle="pill"
                    data-te-target="#tabs-logoutVertical"
                    role="tab"
                    aria-controls="tabs-logoutVertical"
                    aria-selected="false"
                    onClick={btnLogout}
                  >
                    <MdOutlineLogout size={20} />
                    <p className="font-Helvetica">Logout</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="font-Helvetica  col-span-3 bg-white rounded shadow-lg shadow-slate-300 border border-slate-100 p-7">
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-myProfileVertical"
                role="tabpanel"
                aria-labelledby="tabs-myProfile-tab03"
                data-te-tab-active
              >
                <MyProfile />
              </div>
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-myResumeVertical"
                role="tabpanel"
                aria-labelledby="tabs-resume-tab03"
              >
                <MyResume />
              </div>
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-savedJobsVertical"
                role="tabpanel"
                aria-labelledby="tabs-savedJobs-tab03"
              >
                <SavedJobs />
              </div>
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-appliedJobsVertical"
                role="tabpanel"
                aria-labelledby="tabs-appliedJobs-tab03"
              >
                <AppliedJobs />
              </div>
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-createJobAlertVertical"
                role="tabpanel"
                aria-labelledby="tabs-createJobAlert-tab03"
              >
                <CreateJobAlert />
              </div>
              <div
                className="font-Helvetica  hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-changePassVertical"
                role="tabpanel"
                aria-labelledby="tabs-changePass-tab03"
              >
                <ChangePassword />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        data-te-modal-init
        class="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="cameraModalCenter"
        tabindex="-1"
        aria-labelledby="cameraModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div class="pointer-events-auto relative p-5 flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <p className="text-xl text-legavacBlue font-semibold pb-3 border-b">
              Upload Photo
            </p>
            <div className="pt-5">
              <div className="space-y-5">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="text-sm "
                />
                <div className="grid place-items-center space-y-5">
                  <div className="border rounded p-1 w-40 h-40">
                    {file && (
                      <div id="selected-image">
                        <img
                          src={file}
                          alt="Selected image"
                          className="w-[160px] h-[150px] rounded"
                        />
                      </div>
                    )}
                  </div>
                  <button className="font-Helvetica bg-legavacYellow font-semibold rounded text-white py-1 px-5 uppercase cursor-pointer">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
