import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";

function Projects() {
  const [projects, SetProjects] = useState([]);

  const [state, setState] = useState({
    title: "",
    client: "",
    projectStatus: "",
    startDateInprogressProj: "",
    startDatefinishedProj: "",
    endDate: "",
    description: "",
    errors: [],
  });
  const [progress, setProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const funProject_InProgress = () => {
    setProgress(true);
    setFinished(false);
  };
  const funProject_finished = () => {
    setProgress(false);
    setFinished(true);
  };

  const project = [
    {
      title: "Legavac Job Portal",
      client: "Big Boss",
      from: "23 Aug 2022",
      to: "Present",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      title: "Dream Estate",
      client: "Abhita Land Solutions",
      from: "23 Aug 2022",
      to: "23 Aug 2023",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.title) {
      IsValid = false;
      errors["title"] = "required";
    } else {
      errors["title"] = "";
    }
    if (!state.client) {
      IsValid = false;
      errors["client"] = "required";
    } else {
      errors["client"] = "";
    }
    if (!state.description) {
      IsValid = false;
      errors["description"] = "required";
    } else {
      errors["description"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = () => {
    debugger;
    if (validate()) {
      const newData = (data) => [...data, state];
      SetProjects(newData);
      alert("done");
      setState({
        title: "",
        client: "",
        projectStatus: "",
        startDateInprogressProj: "",
        startDatefinishedProj: "",
        endDate: "",
        description: "",
      });
      setShow(false);
      setHide(true);
    } else {
      alert("enter all fields");
    }
  };

  const handleEdit = () => {
    setShow(true);
    setHide(false);
  };

  const [show, setShow] = useState();
  const [hide, setHide] = useState();
  const funShow = () => {
    setShow(true);
    setHide(false);
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
              Projects
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle size={20} className="font-Helvetica  text-blue-500" />
              <p
                className="font-Helvetica  text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div>
            <div className="font-Helvetica  space-y-5">
              <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Title:
                  </p>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={handleChange}
                    value={state.title}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-sm text-red-600">{state.errors.title}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Client:
                  </p>
                  <input
                    type="text"
                    name="client"
                    id="client"
                    onChange={handleChange}
                    value={state.client}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-sm text-red-600">
                      {state.errors.client}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <div className="font-Helvetica  col-span-2 space-y-2">
                  <div className="font-Helvetica  flex space-x-10 items-center">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Project Status:
                    </p>
                    <div className="font-Helvetica  ">
                      <input
                        className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04] "
                        type="radio"
                        name="flexRadioDefault"
                        id="radioDefault01"
                        onClick={funProject_InProgress}
                      />
                      <label
                        className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                        for="radioDefault01"
                      >
                        In Progress
                      </label>
                    </div>
                    <div className="font-Helvetica  ">
                      <input
                        className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04]"
                        type="radio"
                        name="flexRadioDefault"
                        id="radioDefault02"
                        onClick={funProject_finished}
                      />
                      <label
                        className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                        for="radioDefault02"
                      >
                        Finished
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {progress ? (
                <div className="font-Helvetica  grid grid-cols-2 gap-5">
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Starting Date:
                    </p>
                    <input
                      type="date"
                      name="startDateInprogressProj"
                      id="startDateInprogressProj"
                      onChange={handleChange}
                      value={state.startDateInprogressProj}
                      className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {finished ? (
                <div className="font-Helvetica  grid grid-cols-2 gap-5">
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Starting Date:
                    </p>
                    <input
                      type="date"
                      name="startDatefinishedProj"
                      id="startDatefinishedProj"
                      onChange={handleChange}
                      value={state.startDatefinishedProj}
                      className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      End Date :
                    </p>
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      onChange={handleChange}
                      value={state.endDate}
                      className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="font-Helvetica  grid grid-cols-1 gap-5 pt-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Description:
                </p>
                <textarea
                  name="description"
                  id="description"
                  onChange={handleChange}
                  value={state.description}
                  rows={3}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                {state.errors ? (
                  <p className="font-Helvetica  text-sm text-red-600">
                    {state.errors.description}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between pt-5">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}
        <ol className="font-Helvetica  border-l-2 border-info-100">
          {projects.length > 0 &&
            projects?.map((p, idx) => (
              <li key={idx}>
                <div className="font-Helvetica  flex-start md:flex">
                  <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                    <HiOutlineAcademicCap />
                  </div>
                  <div className="font-Helvetica  ml-6 mb-5 block w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                    <div className="font-Helvetica  flex justify-between">
                      <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                        {p.title}
                      </p>
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          size={20}
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          onClick={handleEdit}
                        />
                        <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                    <p className="font-Helvetica  text-sm font-semibold">{p.client}</p>
                    <div className="font-Helvetica  flex space-x-1">
                      <p className="font-Helvetica  text-sm text-slate-500">
                        {p.startDateInprogressProj}
                      </p>
                      <p className="font-Helvetica  text-sm text-slate-500">-</p>
                      <p className="font-Helvetica  text-sm text-slate-500">
                        {p.startDatefinishedProj}
                      </p>
                    </div>
                    <p className="font-Helvetica  text-sm ">{p.description}</p>
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </div>
    </>
  );
}

export default Projects;
