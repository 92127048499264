import React from "react";

function CreateJobAlert() {
  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Create Job Alert
          </p>
        </div>
        {/* <p className="font-Helvetica  text-sm px-3 mb-3">Alerts Keep you up to date on newly added jobs ...!!</p> */}
        <div className="font-Helvetica  pt-8 grid grid-cols-2 gap-5">
          <div className="font-Helvetica  space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Keyword :
            </p>
            <input
              type="text"
              placeholder="Enter keyword"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <div className="font-Helvetica  space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Location :
            </p>
            <input
              type="text"
              placeholder="Enter location"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <div className="font-Helvetica  space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Experience :
            </p>
            <input
              type="text"
              placeholder="Enter experience"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <div className="font-Helvetica  space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Expected Salary :
            </p>
            <input
              type="text"
              placeholder="Enter expected salary"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
            />
          </div>
          <div className="font-Helvetica  space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Email ID :
            </p>
            <input
              type="email"
              placeholder="Enter email ID"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
            />
          </div>
        </div>
        <div className="font-Helvetica  pt-10">
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="font-Helvetica  py-2 px-5 text-sm rounded bg-legavacBlue text-white uppercase"
          >
            Create Job Alert
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateJobAlert;
