import { useFormik } from "formik";
import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";

function SocialLinks() {
  const socialLinks = [
    {
      profile: "Linked In",
      link: "https://www.google.com/",
    },
    {
      profile: "Porfolio",
      link: "https://www.google.com/",
    },
  ];

  const [link, setLink] = useState([]);
  const [state, setState] = useState({
    socialProfile:"",
    socialLink:"",
    errors:[],
  })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.socialProfile) {
      IsValid = false;
      errors["socialProfile"] = "required";
    } else {
      errors["socialProfile"] = "";
    }
    if (!state.socialLink) {
      IsValid = false;
      errors["socialLink"] = "required";
    } else {
      errors["socialLink"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = () => {
    debugger;
    if (validate()) {
      const newData = (data) => [...data, state];
      setLink(newData);
      alert("done");
      setState({
        socialProfile:"",
        socialLink:"",
      });
      setShow(false);
      setHide(true);
    } else {
      alert("enter all fields");
    }
  };

  const handleEdit =() => {
    setShow(true);
    setHide(false);
  }


  const [show, setShow] = useState();
  const [hide, setHide] = useState();
  const funShow = () => {
    setShow(true);
    setHide(false);
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
              Social Links
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle size={20} className="font-Helvetica  text-blue-500" />
              <p
                className="font-Helvetica  text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Social Profile:
                </p>
                <input
                  type="url"
                  name="socialProfile"
                  id="socialProfile"
                  onChange={handleChange}
                  value={state.socialProfile}
                  placeholder="fb,inst,etc profile url"
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                 {state.errors ? (
                    <p className= "text-sm text-red-600">
                      {state.errors.socialProfile}
                    </p>
                  ) : (
                    ""
                  )}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Social Link:
                </p>
                <input
                  type="url"
                  name="socialLink"
                  id="socialLink"
                  onChange={handleChange}
                  value={state.socialLink}
                  placeholder="github,linkedIn,etc url"
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                 {state.errors ? (
                    <p className= "text-sm text-red-600">
                      {state.errors.socialLink}
                    </p>
                  ) : (
                    ""
                  )}
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <ol className="font-Helvetica  border-l-2 border-info-100">
          {link.length>0 && link?.map((l, idx) => (
            <li key={idx}>
              <div className="font-Helvetica  flex-start md:flex">
                <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                  <HiOutlineAcademicCap />
                </div>
                <div className="font-Helvetica  ml-6 mb-5 block w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                  <div className="font-Helvetica  flex justify-between">
                    <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                      {l.socialProfile}
                    </p>
                    <div className="font-Helvetica  flex space-x-2">
                    <MdEdit size={20} className="font-Helvetica  text-green-600 cursor-pointer" onClick={handleEdit} />
                    <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                        />
                        </div>
                  </div>
                  <a
                    href={l.socialLink}
                    target="_blank"
                    className="font-Helvetica  text-sm underline text-blue-500 cursor-pointer"
                  >
                    {l.socialLink}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
}

export default SocialLinks;
