import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { MdSearch } from "react-icons/md";

function AdvanceSearch() {
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const location = [
    { city: "Delhi", count: "20" },
    { city: "Bangalore/Bengaluru", count: "7869" },
    { city: "Mumbai (All Areas))", count: "6637" },
    { city: "Pune", count: "4850" },
    { city: "Chennai", count: "4276" },
    { city: "Hyderabad/Secunderabad", count: "4222" },
    { city: "Mumbai", count: "4175" },
    { city: "Gurgaon/Gurugram", count: "3855" },
    { city: "Noida", count: "3401" },
    { city: "New Delhi", count: "3272" },
    { city: "Ahmedabad", count: "2750" },
    { city: "Nasik/Nashik", count: "2587" },
    { city: "Kolkata", count: "2223" },
    { city: "Chandigarh", count: "1298" },
    { city: "Jaipur", count: "1202" },
    { city: "Navi Mumbai", count: "1156" },
    { city: "Mohali", count: "1026" },
    { city: "Indore", count: "1003" },
    { city: "Coimbatore", count: "825" },
    { city: "Thane", count: "822" },
  ];

  const experience = [
    { year: "0 - 1 Years" },
    { year: "1 - 2 Years" },
    { year: "2 - 5 Years" },
    { year: "5 - 7 Years" },
    { year: "7 - 10 Years" },
    { year: "10 - 15 Years" },
    { year: "15 - * Years" },
  ];

  const roles = [
    { role: "Retail & B2C Sales", rolecount: "5439" },
    { role: "Software Development", rolecount: "4763" },
    { role: "BD / Pre Sales", rolecount: "4316" },
    { role: "Other", rolecount: "4291" },
    { role: "Voice / Blended", rolecount: "4041" },
    { role: "Doctor", rolecount: "2438" },
    { role: "Accounting & Taxation", rolecount: "2103" },
    {
      role: "Customer Success, Service & Operations - Other",
      rolecount: "2068",
    },
    { role: "Recruitment & Talent Acquisition", rolecount: "1586" },
    { role: "Customer Success", rolecount: "1533" },
    { role: "Administration", rolecount: "1184" },
    { role: "Marketing", rolecount: "1087" },
    { role: "Non Voice", rolecount: "1083" },
    { role: "Enterprise & B2B Sales", rolecount: "1075" },
    {
      role: "Content Management ",
      rolecount: "98",
    },
    { role: "Back Office", rolecount: "979" },
    { role: "Digital Marketing", rolecount: "915" },
    { role: "Other Design", rolecount: "906" },
    { role: "HR Operations", rolecount: "836" },
    { role: "Banking Operations", rolecount: "771" },
    { role: "IT Network", rolecount: "755" },
    { role: "Engineering", rolecount: "726" },
    { role: "Operations, Maintenance & Support", rolecount: "595" },
    { role: "Business Intelligence & Analytics", rolecount: "524" },
    { role: "IT Support", rolecount: "491" },
    { role: "Health Informatics", rolecount: "474" },
    { role: "Quality Assurance and Testing", rolecount: "456" },
    { role: "Finance", rolecount: "415" },
    { role: "Subject / Specialization Teacher", rolecount: "409" },
  ];

  const salary = [
    { salary: "0-3 Lakhs", salarycount: "43287" },
    { salary: "3-6 Lakhs", salarycount: "40431" },
    { salary: "6-10 Lakhs", salarycount: "12587" },
    { salary: "10-15 Lakhs", salarycount: "3169" },
    { salary: "15-25 Lakhs", salarycount: "1534" },
    { salary: "25-50 Lakhs", salarycount: "1000" },
    { salary: "50-75 Lakhs", salarycount: "445" },
    { salary: "75-100 Lakhs", salarycount: "177" },
    { salary: "1-5 Cr", salarycount: "166" },
    { salary: "5+ Cr", salarycount: "14" },
  ];

  const companyType = [
    { cotype: "Corporate", cocount: "8785" },
    { cotype: "Foreign MNC", cocount: "3264" },
    { cotype: "Startup", cocount: "1629" },
    { cotype: "Indian MNC", cocount: "1554" },
    { cotype: "Govt/PSU", cocount: "125" },
    { cotype: "MNC", cocount: "79" },
    { cotype: "Others", cocount: "15" },
  ];

  const education = [
    { education: "Any Postgraduate", educount: "22434" },
    { education: "PG Not Required ", educount: "8207" },
    { education: "MBA/PGDM", educount: "4373" },
    { education: "M.Tech", educount: "2041" },
    { education: "Medical-MS/MD", educount: "1981" },
    { education: "MCA", educount: "1848" },
    { education: "MS/M.Sc(Science)", educount: "1818" },
    { education: "CA", educount: "1119" },
    { education: "M.Com", educount: "925" },
    { education: "PG Diploma", educount: "763" },
    { education: "M.Pharma", educount: "641" },
    { education: "M.A", educount: "419" },
    { education: "Any Graduate", educount: "39247" },
    { education: "B.Tech/B.E.", educount: "6317" },
    { education: "Graduation Not Required", educount: "6257" },
    { education: "Diploma", educount: "3484" },
    { education: "B.Sc", educount: "3429" },
    { education: "B.Com", educount: "2812" },
    { education: "B.B.A/ B.M.S", educount: "2045" },
    { education: "BCA", educount: "2020" },
    { education: "MBBS", educount: "1766" },
  ];

  const companies = [
    { compname: "Marriott", compcount: "346" },
    { compname: "Tata Capital Forex", compcount: "225" },
    { compname: "Muthoot Finance", compcount: "168" },
    { compname: "Genpact", compcount: "164" },
    { compname: "Wipro", compcount: "151" },
    { compname: "IDFC FIRST Bank", compcount: "123" },
    { compname: "ICICI Prudential Life", compcount: "121" },
    { compname: "Teleperformance (TP)", compcount: "114" },
    { compname: "Tech Mahindra", compcount: "105" },
    { compname: "EXL", compcount: "89" },
    { compname: "Metlife", compcount: "80" },
    { compname: "BYJUS", compcount: "76" },
    { compname: "Ienergizer", compcount: "66" },
    { compname: "Accenture", compcount: "65" },
    { compname: "IndiaMART", compcount: "62" },
    { compname: "IBM", compcount: "60" },
    { compname: "BNY Mellon", compcount: "58" },
    { compname: "Cognizant", compcount: "58" },
    { compname: "SBI Card", compcount: "55" },
    { compname: "Randstad", compcount: "54" },
    { compname: "Tata Communications", compcount: "45" },
    { compname: "Flipkart", compcount: "42" },
    { compname: "Asian Paints", compcount: "40" },
    {
      compname: "Tech Mahindra Business Services",
      compcount: "34",
    },
    { compname: "Trigent Software", compcount: "33" },
    { compname: "Info Edge", compcount: "29" },
    { compname: "IIFL Finance", compcount: "29" },
    { compname: "Practo", compcount: "27" },
    { compname: "Bankbazaar", compcount: "26" },
    { compname: "Fortis", compcount: "26" },
  ];

  const jobtype = [
    {
      job: "Full Time",
    },
    {
      job: "Part Time",
    },
  ];

  const shifts = [
    {
      shift: "Day Shift",
    },
    {
      shift: "Night Shift",
    },
  ];

  const work = [
    { mode: "Work from office", modecount: "52402" },
    { mode: "Hybrid", modecount: "795" },
    { mode: "Permanent Remote / WFH", modecount: "558" },
  ];

  const skills = [
    { skill: "Android Jobs", skillcount: "20" },
    { skill: "WordPress Jobs", skillcount: "20" },
    { skill: "eCommerce Jobs", skillcount: "20" },
    { skill: "Design Jobs", skillcount: "20" },
    { skill: "Mobile Jobs", skillcount: "20" },
    { skill: "MySQL Jobs", skillcount: "20" },
    { skill: "SEO Jobs", skillcount: "20" },
    { skill: "Website Design Jobs", skillcount: "20" },
    { skill: "Web Development Jobs", skillcount: "20" },
    { skill: "Web Design Jobs", skillcount: "20" },
    { skill: "Programming Jobs", skillcount: "20" },
    { skill: "JavaScript Jobs", skillcount: "20" },
    { skill: "Developer Jobs", skillcount: "20" },
    { skill: "Software Jobs", skillcount: "20" },
  ];

  const postedby = [
    {
      post: "Company Jobs",
      postcount: "43163",
    },
    {
      post: "Consultant Jobs",
      postcount: "11521",
    },
  ];

  return (
    <>
      <div className="font-Helvetica  submenu-list divide-y">
        {/*Location*/}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(0)}
          >
            <p className="font-Helvetica  font-medium">Location</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 0 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 0 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {location?.map((l, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{l.city}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({l.count})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Experience */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(1)}
          >
            <p className="font-Helvetica  font-medium">Experience</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 1 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {experience?.map((e, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{e.year}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Skills */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(2)}
          >
            <p className="font-Helvetica  font-medium">Role/Designation</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 2 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {roles?.map((r, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{r.role}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({r.rolecount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(12)}
          >
            <p className="font-Helvetica  font-medium">Skills</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 12 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 12 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {skills?.map((s, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{s.skill}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({s.skillcount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
        {/* Salary */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(3)}
          >
            <p className="font-Helvetica  font-medium">Salary</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 3 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {salary?.map((s, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{s.salary}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({s.salarycount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Company Type */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(4)}
          >
            <p className="font-Helvetica  font-medium">Company Type</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 4 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 4 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {companyType?.map((c, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{c.cotype}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({c.cocount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Education */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(5)}
          >
            <p className="font-Helvetica  font-medium">Education</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 5 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 5 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {education?.map((e, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{e.education}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({e.educount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Companies */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(6)}
          >
            <p className="font-Helvetica  font-medium">Companies</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 6 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 6 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {companies?.map((comp, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{comp.compname}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">
                      ({comp.compcount})
                    </p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Job Type */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(8)}
          >
            <p className="font-Helvetica  font-medium">Job Type</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 8 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 8 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {jobtype?.map((j, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{j.job}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Shift */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(9)}
          >
            <p className="font-Helvetica  font-medium">Shift</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 9 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 9 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {shifts?.map((sh, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{sh.shift}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Work Mode */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(10)}
          >
            <p className="font-Helvetica  font-medium">Work Mode</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 10 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 10 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {work?.map((w, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{w.mode}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({w.modecount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Posted by */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(11)}
          >
            <p className="font-Helvetica  font-medium">Posted by</p>
            <FontAwesomeIcon
              icon={subMenuOpen === 11 ? faMinus : faPlus}
              className="font-Helvetica  minus-plus"
            />
          </div>

          <ul className={`sub-menu ${subMenuOpen === 11 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {postedby?.map((by, idx) => (
                <li className="font-Helvetica  menu-item flex justify-between pt-0.5">
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{by.post}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({by.postcount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default AdvanceSearch;
