import React from "react";
import { MdSearch } from "react-icons/md";
import { IMAGES } from "../../stores";

function Article() {
  return (
    <>
      <div>Article</div>
      <div className="font-Helvetica   container mx-auto lg:px-20 md:px-10 px-0">
        <div className="font-Helvetica  pt-20  space-y-5">
          <div className="font-Helvetica  bg-slate-50 mt-5">
            <p className="font-Helvetica  text-3xl text-center tracking-wide  text-legavacBlue font-bold p-5 ">
              Articles{" "}
            </p>
          </div>
          <div className="font-Helvetica  lg:grid lg:grid-cols-12 md:grid-cols-7 sm:grid-cols-1 flex  md:flex flex-col-reverse px-2">
            {/* image */}
            <div className="font-Helvetica  lg:col-span-10 md:col-span-5 col-span-1 space-y-5 lg:px-20">
              <div className="grid lg:grid-cols-2 gap-5 md:grid-cols-2 grid-cols-1">
                <div className="font-Helvetica    ">
                  <div>
                    <img
                      src={IMAGES.lawyer2}
                      alt="articale image"
                      className="font-Helvetica  lg:w-[600px] lg:h-[400px]   border border-2"
                    />
                  </div>

                  <div className="font-Helvetica p-5">
                    <p className="font-Helvetica  font-bold text-legavacBlue  py-2 text-xl">
                      Impact of Pandemic on Legal profession
                    </p>
                    <p className="font-Helvetica  text-sm">
                      Impact of Pandemic on Legal profession ...
                    </p>
                    <p
                      className="font-Helvetica  text-red-500 lg:py-5 md:py-5 py-2 font-semibold   cursor-pointer "
                      data-te-toggle="modal"
                      data-te-target="#leftTopModal"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      View More ...
                    </p>
                  </div>
                </div>
                <div className="font-Helvetica    ">
                  <img
                    src={IMAGES.article}
                    alt="articale image"
                    className="font-Helvetica   lg:w-[600px] lg:h-[400px] "
                  />

                  <div className="font-Helvetica  p-5">
                    <p className="font-Helvetica  font-bold text-legavacBlue  py-2 text-xl">
                      Career Opportunity for Lawyers in food Industry Post Covid
                      Era
                    </p>
                    <p className="font-Helvetica  text-sm">
                      Career Opportunity for Lawyers in food Industry - Post
                      Covid Era ...
                    </p>
                    <p
                      className="font-Helvetica  text-red-500 lg:py-5 md:py-5 py-2 font-semibold text-right cursor-pointer "
                      data-te-toggle="modal"
                      data-te-target="#leftTopModal"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      View More ...
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid lg:grid-cols-2 gap-5 md:grid-cols-2 grid-cols-1">
                <div className="font-Helvetica    ">
                  <img
                    src={IMAGES.lawyer2}
                    alt="articale image"
                    className="font-Helvetica   lg:w-[600px] lg:h-[400px] "
                  />

                  <div className="font-Helvetica    p-5  ">
                    <p className="font-Helvetica  font-bold text-legavacBlue  py-2 text-xl">
                      Think Beyond Regular Litigation Lawyers in Corporate World
                    </p>
                    <p className="font-Helvetica  text-sm">
                      Think Beyond Regular Litigation Lawyers in Corporate World
                      ...
                    </p>
                    <p
                      className="font-Helvetica  text-red-500 lg:py-5 md:py-5 py-2 font-semibold text-right cursor-pointer "
                      data-te-toggle="modal"
                      data-te-target="#leftTopModal"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      View More ...
                    </p>
                  </div>
                </div>
                <div className="font-Helvetica    ">
                  <img
                    src={IMAGES.article}
                    alt="articale image"
                    className="font-Helvetica   lg:w-[600px] lg:h-[400px] "
                  />

                  <div className="font-Helvetica 
                  ">
                    <p className="font-Helvetica  font-bold text-legavacBlue  py-2 text-xl">
                      Impact of Pandemic on Legal profession
                    </p>
                    <p className="font-Helvetica  text-sm">
                      Impact of Pandemic on Legal profession ...
                    </p>
                    <p
                      className="font-Helvetica  text-red-500 lg:py-5 md:py-5 py-2 font-semibold text-right cursor-pointer "
                      data-te-toggle="modal"
                      data-te-target="#leftTopModal"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      View More ...
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* search box */}

            <div className="font-Helvetica  col-span-2 place-items-start">
              <div>
                <div className="font-Helvetica  ">
                  <MdSearch
                    size={20}
                    className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacYellow focus:border-legavacBlue focus:outline-none hover:outline-none"
                  />
                </div>
              </div>
              <div className="font-Helvetica  lg:block md:hidden hidden">
                <p className="font-Helvetica  text-xl font-semibold">
                  Recent Post
                </p>
                <ul className="font-Helvetica  ">
                  <li className="font-Helvetica  py-5 px-0 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue hover:px-0">
                    <p className="font-Helvetica">
                      <span className="font-Helvetica font-medium"> </span>
                      Impact of Pandemic{" "}
                    </p>
                    {/* <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                    March 16, 2016 Nunc libero
                  </p> */}
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    <p className="font-Helvetica">
                      <span className="font-Helvetica  font-medium"> </span>
                      Career Opportunity for Lawyers{" "}
                    </p>
                    {/* <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                    March 16, 2016 Nunc libero
                  </p> */}
                  </li>
                  {/* <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                  <p className="font-Helvetica">
                    <span className="font-Helvetica  font-medium"> </span>
                    Guiding Syrian Refugees in finding Menaningful Employment{" "}
                  </p>
                  <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                    March 16, 2016 Nunc libero
                  </p>
                </li> */}
                </ul>
              </div>
              <div className="font-Helvetica   pt-20 lg:block md:hidden hidden">
                <p className="font-Helvetica  text-xl font-semibold mb-2">
                  categories
                </p>
                <ul>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    Legal
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    Chartered Accountants
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    Company Secretary
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    Finanace
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    IT
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    Accounts
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}

      <div
        data-te-modal-init
        className="font-Helvetica  fixed top-0 left-0 right-0 z-[1055] hidden h-full lg:w-full md:w-80 overflow-y-auto overflow-x-hidden outline-none container mx-auto p-5"
        id="leftTopModal"
        tabIndex="-1"
        aria-labelledby="leftTopModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="font-Helvetica  pointer-events-none  w-full h-full translate-x-[-100px] opacity-0 transition-all duration-500 ease-in-out "
        >
          <div className="font-Helvetica  pointer-events-auto  flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="font-Helvetica  flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              {" "}
              <p className="font-Helvetica  font-bold text-legavacBlue  py-2 text-xl">
                What is Lorem Ipsum?
              </p>
              <button
                type="button"
                className="font-Helvetica  box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="font-Helvetica  h-6 w-6"
                >
                  <path
                    stroke-linecap="font-Helvetica  round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              className="font-Helvetica    p-4 grid overflow-y-auto place-items-center"
              data-te-modal-body-ref
            >
              <div>
                <img
                  src={IMAGES.article}
                  alt="articale image"
                  className="font-Helvetica   lg:w-[600px] lg:h-[400px] "
                />
              </div>
              <div>
                <p className="font-Helvetica  text-sm lg:px-20 md:px-10 px-5 py-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was Lorem Ipsum is simply dummy text
                  of the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not
                  only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was Lorem Ipsum is simply dummy text
                  of the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not
                  only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was Lorem Ipsum is simply dummy text
                  of the printing and typesetting industry. Lorem Ipsum has been
                  the industry's standard dummy text ever since the 1500s, when
                  an unknown printer took a galley of type and scrambled it to
                  make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not
                  only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was
                </p>
              </div>
              <div className="font-Helvetica  ">
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica   text-xl text-white bg-legavacYellow rounded  py-2 px-5"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Article;
