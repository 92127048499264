import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";

function CourseCrtfct() {
  const course = [
    {
      cerificate: "Full Stack Web Development",
      issuedBy: "Edureka",
      validity: "Lifetime",
    },
    {
      cerificate: "Full Stack Web Development",
      issuedBy: "Udemy",
      validity: "21 Feb, 2023",
    },
  ];

  const [CourseCrtfct, setCourseCrtfct] = useState([]);
  const [state, setState] = useState({
    certificate:"",
    issuedBy:"",
    validDate:"",
    errors:[],
  })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.certificate) {
      IsValid = false;
      errors["certificate"] = "required";
    } else {
      errors["certificate"] = "";
    }
    if (!state.issuedBy) {
      IsValid = false;
      errors["issuedBy"] = "required";
    } else {
      errors["issuedBy"] = "";
    }
    if (!state.validDate) {
      IsValid = false;
      errors["validDate"] = "required";
    } else {
      errors["validDate"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = () => {
    debugger;
    if (validate()) {
      const newData = (data) => [...data, state];
      setCourseCrtfct(newData);
      alert("done");
      setState({
        cerificate:"",
        issuedBy:"",
        validDate:"",
      });
      setShow(false);
      setHide(true);
    } else {
      alert("enter all fields");
    }
  };

  const handleEdit =() => {
    setShow(true);
    setHide(false);
  }


  const [show, setShow] = useState();
  const [hide, setHide] = useState();
  const funShow = () => {
    setShow(true);
    setHide(false);
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
              Courses & Certificates
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle size={20} className="font-Helvetica  text-blue-500" />
              <p
                className="font-Helvetica  text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>

        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Certificate:
                </p>
                <input
                  type="text"
                  name="certificate"
                  id="certificate"
                  onChange={handleChange}
                  value={state.certificate}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                 {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.certificate}
                    </p>
                  ) : null}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Issued by:
                </p>
                <input
                  type="text"
                  name="issuedBy"
                  id="issuedBy"
                  onChange={handleChange}
                  value={state.issuedBy}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                 {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.issuedBy}
                    </p>
                  ) : null}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Validity Date:
                </p>
                <input
                  type="date"
                  name="validDate"
                  id="validDate"
                  onChange={handleChange}
                  value={state.validDate}
                  className="font-Helvetica  border text-sm border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                 {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.validDate}
                    </p>
                  ) : null}
                <div className="font-Helvetica  flex space-x-1 items-center">
                  <input
                    type="checkbox"
                    name="lifeTime"
                    id="lifeTime"
                    onChange={handleChange}
                    value={state.lifeTime}
                  />
                  <p className="font-Helvetica  text-sm text-slate-700 tracking-wide">
                    Life time
                  </p>
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}

        <ol className="font-Helvetica  border-l-2 border-info-100">
          {CourseCrtfct.length>0 && CourseCrtfct?.map((c, idx) => (
            <li key={idx}>
              <div className="font-Helvetica  flex-start md:flex">
                <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                  <HiOutlineAcademicCap />
                </div>
                <div className="font-Helvetica  ml-6 mb-5 block w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                  <div className="font-Helvetica  flex justify-between">
                    <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                      {c.certificate}
                    </p>
                    <div className="font-Helvetica  flex space-x-2">
                      <MdEdit
                        size={20}
                        className="font-Helvetica  text-green-600 cursor-pointer"
                        onClick={handleEdit}
                      />
                      <MdDelete
                        size={20}
                        className="font-Helvetica  text-red-600 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="font-Helvetica  flex space-x-1">
                    <p className="font-Helvetica  text-sm text-slate-500">Issued by:</p>
                    <p className="font-Helvetica  text-sm text-slate-700">{c.issuedBy}</p>
                  </div>
                  <div className="font-Helvetica  flex space-x-1">
                    <p className="font-Helvetica  text-sm text-slate-500">Validity:</p>
                    <p className="font-Helvetica  text-sm text-slate-700">{c.validDate}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
}

export default CourseCrtfct;
